'use strict';
import _ from 'lodash';

import template from './../templates/ad.ejs';
import BaseView from './../views/base';

export default BaseView.extend({
    template: template,
    tagName: 'div',
    className: 'view-ad',
    /**
     * [events description]
     * @return {[type]} [description]
     */
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {});
    },
    /**
     * [initialize description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    initialize: function() {
        _.bindAll(this, 'render');
    },
    /**
     * [render description]
     * @return {[type]} [description]
     */
    render: function() {
        console.log('ad view', _.result(this, 'events'), this.model);
        this.$el.html(this.template(this.model));
        return this;
    }
});
