'use strict';
import Backbone from 'backbone';
import _ from 'lodash';

export default Backbone.Model.extend({
    _validation: null,

    initialize: function(attributes, options) {
        this.fieldData = options.fieldData;
        this.parseFields();

        _.extend(Backbone.Validation.patterns, {
            phone: /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i
        });
    },

    getFieldData: function(id) {
        if (id) {
            return _.findWhere(this.fieldData, {
                id: id
            });
        } else {
            return this.fieldData;
        }
    },

    save: function() {
        this.validate();
        //Don't do anything else.
    },

    parseFields: function() {
        var attrs = {};
        for (var i = 0; i < this.fieldData.length; i++) {
            var field = this.fieldData[i];

            if (field.type !== 'section') {
                attrs[field.id] = '';

                this.set(attrs, {
                    silent: true
                });
            }
        }
    },

    validation: function() {
        if (this._validation) {
            return this._validation;
        }

        var fields = this.fieldData;
        var validation = {};

        for (var i = 0; i < fields.length; i++) {
            var field = fields[i];
            var pattern = field.validation_pattern;
            var isRegex = new RegExp('/(.*)/(.*)');

            if (
                field.options_list.length === 1 &&
                field.options_list[0] === ''
            ) {
                field.options_list = [];
            }

            if (isRegex.test(pattern)) {
                var match = pattern.match(isRegex);
                pattern = new RegExp(match[1], match[2]);
            }

            validation[field.id] = {
                required: field.required === 'true',
                msg: field.error_message
            };

            if (field.type === 'file' && field.options_list.length > 0) {
                validation[field.id].pattern = new RegExp(
                    '.(' + field.options_list.join('|') + ')$',
                    'i'
                );
            } else {
                validation[field.id].pattern = pattern;
            }

            if (
                (field.type === 'radio' || field.type === 'text') &&
                field.options_list &&
                field.options_list.length > 0
            ) {
                validation[field.id].oneOf = field.options_list;
            }

            if (field.type === 'checkbox' && field.required === 'true') {
                validation[field.id].acceptance = true;
            }

            if (field.min_length !== '') {
                validation[field.id].minLength = parseInt(field.min_length);
            }
            if (field.max_length !== '') {
                validation[field.id].maxLength = parseInt(field.max_length);
            }
            if (field.equalTo !== '') {
                validation[field.id].equalTo = parseInt(field.equalTo);
            }
        }

        this._validation = validation;

        return validation;
    }
});
