import Backbone from 'backbone';
import _ from 'lodash';
import $ from 'jquery';
import FlexModel from 'tscom-flex';
import { VERSION_CHECK } from '../util/constants';

('use strict');
export default FlexModel.extend({
    required_fields: [
        'id',
        'topics',
        'ruleset_collection_ids',
        'user_name',
        'message',
        'country',
        'state',
        'custom_parameters'
    ],

    optional_fields: [
        //'ruleset_collection_ids', //only needs this to go to tailor
        'screen_name',
        'timestamp',
        'image',
        'video',
        'avatar',
        'user_score'
    ],

    version_check: VERSION_CHECK,

    initialize: function(attrs, options) {
        if (options && options.version_id) {
            this.version_id = options.version_id;
        }
        if (options && options.geoCountry) {
            this.geoCountry = options.geoCountry;
        } else {
            this.geoCountry = '';
        }
        if (options && options.geoState) {
            this.geoState = options.geoState;
        } else {
            this.geoState = '';
        }
        console.log('flex init options', options);
    },

    setUpData: function(model) {
        console.log('flex 1', this, model);

        var customParameters = _.extend(
            { 'ip-user': this.get('userIp') || '--' },
            model
        );

        if (customParameters.ruleset_collection_id) {
            delete customParameters.ruleset_collection_id;
        }
        if (customParameters.ruleset_collection_ids) {
            delete customParameters.ruleset_collection_ids;
        }

        this.set({
            id: _.uniqueId(
                new Date().getTime() + '' + Math.floor(Math.random() * 99)
            ),
            //  'ruleset_collection_ids': '',
            user_name: model.user_name || '--',
            message: model.message || '--',
            country: model.country || this.geoCountry,
            state: model.state || this.geoState,
            custom_parameters: JSON.stringify(customParameters),
            video: '',
            image: ''
        });

        // Populate the optional Flex fields if the form model has matching fields.
        for (var i = 0; i < this.optional_fields.length; i++) {
            var name = this.optional_fields[i];
            var value = model[name];
            if (!_.isUndefined(value) && !_.isNull(value)) {
                this.set({ name: value });
            }
        }
        console.log('flex 2', this);
    },
    save: function(attributes, options) {
        //Flex requires the ID to be set.
        if (
            this.get('id') === '' ||
            this.get('id') === undefined ||
            this.get('id') === null
        ) {
            this.set({ id: this.generateUniqueID() }, { silent: true });
        }

        //always set timestamp because it was overwriting others with same
        //timestamp
        this.set({ timestamp: Date.now() }, { silent: true });

        var params = {
            url: this.urlRoot(),
            type: 'POST',
            dataType: 'JSON'
        };

        if (options && options.url) {
            options.url = this.prepareUrl(options.url);
        }

        _.extend(params, options);

        return Backbone.Model.prototype.save.call(this, attributes, params);
    },
    getUserIp: function() {
        // add in a cachebuster for the ip fetching
        var cacheBuster =
            Math.floor(Math.random() * 10000) + new Date().getTime();

        return $.ajax({
            url: '/getClientIp.php?' + cacheBuster,
            type: 'GET',
            dataType: 'json',
            data: {}
        });
    }
});
