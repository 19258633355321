'use strict';
import _ from 'lodash';
import Backbone from 'backbone';

export default Backbone.Model.extend({
    localStorage: null,
    url: '',
    defaults: {
        // from CMS
        type: '', // image|video|text|topic
        active: '', // "1"|"0"
        state: '', // on|off
        label: '',
        topics: '',
        keyword: '',
        include_comments: '', // true|false
        contains: '', // contains_link|contains_image|contains_reply|contains_video|contains_retweet
        notContains: '', // contains_link|contains_image|contains_reply|contains_video|contains_retweet
        // internal
        user_state: undefined
    },
    /**
     *
     */
    initialize: function(attrs, options) {
        this.localStorage = new Backbone.LocalStorage(options.wid + '_filter');

        // console.log('filter.js model initialize', attrs, options);
        this.set(
            'selected',
            /(on)/i.test(attrs.user_state) ||
                (_.isUndefined(attrs.user_state) && /(on)/i.test(attrs.state))
        );
        // fetch attributes from localStorage
        this.fetch({ ajaxSync: false });
        this.on('change', function(model) {
            this.save(model.attributes, { ajaxSync: false });
        });
    },
    /**
     *
     */
    destroy: function() {
        console.warn('The filter model does not support "destroy"');
    },
    /**
     *
     */
    parse: function(response) {
        // console.log('filter.js model parse', response, options);
        response.selected =
            /(on)/i.test(response.user_state) ||
            (_.isUndefined(response.user_state) &&
                /(on)/i.test(response.state));
        return response;
    }
});
