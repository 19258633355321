'use strict';
import Facebook from 'tscom-facebook';

export default Facebook.extend({
    share: function(url) {
        FB.ui({
            method: 'share',
            href: url
        });
    }
});
