'use strict';
import _ from "lodash";

import template from './../templates/error.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';

export default BaseView.extend({
    template: template,
    tagName: 'div',
    className: 'view-error',
    events: function () {
      return _.extend(_.result(BaseView.prototype, 'events'), {
        'click .close': 'close'
      })
    },
    /**
     * [initialize description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    initialize: function () {
        _.bindAll(this, 'render');
    },
    /**
     * [render description]
     * @return {[type]} [description]
     */
    render: function () {
        console.log('error view', _.result(this, 'events'));
        this.$el.html(this.template(this.model));
        return this;
    },
    /**
     * [close description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    close: function (e) {
        e.preventDefault();
        console.log('close');
        PubSub.trigger('_back');
    }
});
