'use strict';

import Model from './../models/moderation';
import _ from 'lodash';
import Backbone from 'backbone';
import $ from 'jquery';

export default Backbone.Collection.extend({
    model: Model,
    /**
     * Even though we can request a comma delimited list of topic IDs, the remaining params
     * (i.e. keywords, contains...) are global.  In order for the filters in this app to weild
     * more granular control, we will need each model to make it's own call.
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    fetch: function() {
        var promises = _.invoke(this.models, 'fetch');

        return $.when.apply($, promises);
    }
});
