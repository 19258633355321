'use strict';
import _ from 'lodash';
import template from './../templates/tile-ad.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';

export default BaseView.extend({
    template: template,
    tagName: 'li',
    className: 'view-tile view-tile-ad',
    /**
     * [events description]
     * @return {[type]} [description]
     */
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {});
    },
    /**
     * [initialize description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    initialize: function() {
        _.bindAll(this, 'render');
        this.listenTo(
            PubSub,
            'AnimationFrame',
            _.bind(this.setItemHeight, this)
        );
    },
    /**
     * [render description]
     * @return {[type]} [description]
     */
    render: function() {
        console.log('tile view', _.result(this, 'events'), this.$el);

        this.$el.html(this.template(this.model.data));

        return this;
    },
    /**
     * [relayVoteItemHeight description]
     * @return {[type]} [description]
     */
    setItemHeight: function() {
        if (!this.isActiveView) {
            return;
        }
        var rect = this.$el[0].getBoundingClientRect();
        if (
            typeof this.myHeight === 'undefined' ||
            this.myHeight !== Math.floor(rect.width)
        ) {
            this.myHeight = Math.floor(rect.width);
            // console.log('setItemHeight', this.myHeight);
            PubSub.trigger('relayVoteItemHeight', this.myHeight);
        }
    }
});
