'use strict';
import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';
import PubSub from './PubSub';

import router from './routes/router';

import ModelCms from 'tscom-cms';
import ModelGeo from 'tscom-geo';
import ModelBase from './models/base';
import ModelGoogle from './models/google-analytics';
import ModelModeration from './models/moderation';
import ModelFacebook from './models/facebook';
import ModelFlex from './models/flex';
import ModelVine from './models/vine';

import CollectionMessage from './collections/message';
import CollectionModeration from './collections/moderation';
import CollectionFilter from './collections/filter';
import CollectionInvalidMessage from './collections/invalid-message';


import { getQueryParamByName, findByValues, isTrue } from './util/helpers';
/**
 * @param
 *     options - {
 *       widget_id: 'data/data.json' // REQUIRED string
 *       container: $('#myWidget')   // REQUIRED string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 *       modal:     $('#wrapper')    // string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 *       uniqueId:  'name-type-namespace-datetime'           // string
 *       cssUrl:    'http://a1.telesocpe.tv/styles/main.css' // string (absolute path)
 *       hashState: false                                    // boolean
 *     }
 */
function Controller(options) {
    options = options || {};
    options.widget_id = getQueryParamByName('wid') || options.widget_id;
    options.hashState = options.hashState || false;

    // define flags for moderation
    var isInitLoad = true;
    var isInitPositionSet = false;
    var fetchingOldMessages = false;
    var holdFetchOld = false;
    var timer = null;

    var controller = {
        Models: {},
        Collections: {},
        Views: {},
        Routers: {},
        userIp: '--',

        /**
         * [updateFilterCollection description]
         * @param  {Backbone.Model} model   [description]
         * @param  {[type]}         value   [description]
         * @param  {Object}         options [description]
         */
        updateFilterCollection: function(model, value) {
            var data = value;

            // console.log('updateFilterCollection', model, value);

            controller.Collections.Filter.set(data, { merge: true });
            if (controller.Collections.Filter.toJSON()) {
                controller.setMessagesFromModeration();
            }

            PubSub.trigger('header:render');
        },

        /**
         * [filtersChanged description]
         * @param  {Object} evt [description]
         */
        filtersChanged: function(evt) {
            // console.log('controller.filtersChanged', filters.toJSON());
            PubSub.trigger('reset:tiles');
            if (evt.noRefresh) {
                isInitLoad = false;
            } else {
                isInitLoad = true;
            }
            controller.setMessagesFromModeration();
        },

        /**
         * [getModerationModelFromFilters description]
         * @return {Array} moderationModels
         */
        getModerationModelsFromFilters: function() {
            var topicFilters = _.filter(
                controller.Collections.Filter.toJSON(),
                { selected: true, type: 'topic' }
            );
            var typeFilters = _.map(
                _.pluck(
                    _.filter(controller.Collections.Filter.toJSON(), function(
                        item
                    ) {
                        return (
                            item.selected === true &&
                            /(text|image|video)/i.test(item.type)
                        );
                    }),
                    'type'
                ),
                function(item) {
                    return item.toLowerCase();
                }
            );

            var moderationModels = [];
            var moderationModelsOlder = [];

            var offset =
                controller.Models.Cms.get('text')['view_mosaic'][
                    'customizations'
                ]['moderation_topic_offset'] != ''
                    ? controller.Models.Cms.get('text')['view_mosaic'][
                          'customizations'
                      ]['moderation_topic_offset']
                    : '50';

            _.forEach(topicFilters, function(value) {
                if (!_.isEmpty(value.topics)) {
                    var params = {
                        topic_id: value.topics,
                        offset: '-' + offset
                    };

                    if (!_.isEmpty(value.keyword)) {
                        _.extend(params, {
                            keyword: value.keyword,
                            include: '' + /(true)/i.test(value.keyword_include)
                        });
                    }

                    if (
                        _.without(typeFilters, 'text').length > 0 &&
                        _.indexOf(typeFilters, 'text') === -1
                    ) {
                        _.extend(params, {
                            contains: _.trim(
                                _.without(typeFilters, 'text')
                                    .join(' ')
                                    .replace(
                                        /(^|\s)([a-z\d-]+)/gi,
                                        'contains_$2,'
                                    ),
                                ','
                            )
                        });
                    } else if (
                        typeFilters.length === 1 &&
                        _.indexOf(typeFilters, 'text') > -1
                    ) {
                        var notContains = [];
                        if (_.indexOf(typeFilters, 'image') === -1) {
                            notContains.push('contains_image');
                        }
                        if (_.indexOf(typeFilters, 'video') === -1) {
                            notContains.push('contains_video');
                        }
                        _.extend(params, {
                            notContains: notContains.join(',')
                        });
                    }

                    var model = new ModelModeration({
                      topic_id: controller.Models.Cms.get('settings').topic_id,
                      moderated: 'true',
                      positionDirection: 'new'
                    }, params);

                    var modelOld = new ModelModeration({
                      topic_id: controller.Models.Cms.get('settings').topic_id,
                      moderated: 'true',
                      positionDirection: 'old',
                      nextPosition: null
                    }, params);

                    moderationModels.push(model);
                    moderationModelsOlder.push(modelOld);
                }
            });

            return {
              moderationModels: moderationModels,
              moderationModelsOlder: moderationModelsOlder
            };
        },
        /**
         * Sets the models for the moderation collection then calls fetch
         */
        setMessagesFromModeration: function() {
          const { moderationModels, moderationModelsOlder } = controller.getModerationModelsFromFilters();

          if (moderationModels.length === 0) {
            controller.Collections.Moderation.reset();
            controller.Collections.Message.reset();
            return;
          }

          controller.Collections.Moderation.reset(moderationModels);
          this.fetchModeration().then(function() {
            controller.setTopicIdsNextPosition(moderationModelsOlder);
          });
        },
        setTopicIdsNextPosition: function(models) {

          models.forEach(function (model, i) {
            const parsedPos = parseInt(controller.initPositions[i]);
            const nextPos = isNaN(parsedPos) ? '' : parsedPos + 1;
            model.set('nextPosition', nextPos)
          });
          
          controller.Collections.ModerationOld.reset(models);
          if (!isInitPositionSet) isInitPositionSet = true;
        },
        /**
         * Merges all the fetched messages from each moderation model
         * @param  {Backbone.Collection} moderationCollection
         * @return {Array}               mergedData
         */
        mergeFetchedData: function(moderationCollection) {
            var topicFilters = _.filter(
                controller.Collections.Filter.toJSON(),
                { selected: true, type: 'topic' }
            );
            var messages = _.pluck(moderationCollection.toJSON(), 'messages');

            // console.log('Moderation FETCH:',  messages)

            var mergedData = new Array();

            if (!isInitPositionSet) {
                this.initPositions = new Array();
            }

            this.tagIndex = 0;
            _.forEach(
                messages,
                _.bind(function(value) {
                    _.forEach(
                        value,
                        _.bind(function(message) {
                            // tag each message to reference which filter it came from
                            message.filter_tag = topicFilters[this.tagIndex].id;

                            // since some moderation items have the same ID and the collection does not like it
                            // we store the id as post_id and create a new ID based on an underscore and ID for the pull from Moderation
                            if (typeof message.post_id != 'undefined') {
                                return;
                            }
                            message.post_id = message.id;
                            message.id = message.id + '_' + this.tagIndex;

                            console.log(message.id);
                        }, this)
                    );

                    //save last position for next fetch
                    if (!isInitPositionSet) {
                        var lastVal = _.last(value);

                        if (lastVal) {
                            this.initPositions.push(lastVal.position);
                        } else {
                            this.initPositions.push('');
                        }
                    }

                    this.tagIndex++;
                    mergedData = _.union(mergedData, value);
                }, this)
            );

            //sort merged data in descending order
            mergedData.sort(function(a, b) {
                if (a.position > b.position) {
                    return 1;
                }
                if (a.position < b.position) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });

            // FOR DEBUGGING PURPOSES ONLY
            // _.each(mergedData, function(message, index) {
            //   console.log("mergedData[" + index + "]ID: "+ message.id + " | position: "+ message.position +" | "+ message.message.slice(0,30));
            // });

            return mergedData;
        },

        /**
         * Fetches from Moderation for INITIAL and NEW posts
         */
        fetchModeration: function() {

          var def = $.Deferred();

          controller.Collections.Moderation.fetch().then(function() {
            
            var mergedData = controller.mergeFetchedData(controller.Collections.Moderation);
            var messagesIds = _.pluck(controller.Collections.Message.toJSON(), 'id');
            var newData = findByValues(mergedData, 'id', _.difference(_.pluck(mergedData, 'id'), messagesIds));
            var validatedData = this.filterInvalidMessages(newData);
                    
            if (!_.isEmpty(mergedData)) {
              // set the timer when we fetch the initial messages
              if (isInitLoad) {
                controller.Collections.Message.reset(mergedData);
                controller.resetTimer();
                isInitLoad = false;
                return def.resolve();
              }

              if (validatedData.length > 0) {
                controller.Collections.Message.add(validatedData, { at: 0 });
                PubSub.trigger('mosaic:prepend', validatedData);
              }

            } else {
              PubSub.trigger('navigate', 'empty');
            }

            def.resolve();

          }.bind(this));

          return def;
        },

        /**
         * Fetches from Moderation for OLD posts
         */
        fetchModerationOld: function() {
            if (fetchingOldMessages || holdFetchOld) return;
            else fetchingOldMessages = true;

            // The collection will call .fetch on each of our models and return a $.Deferred
            controller.Collections.ModerationOld.fetch().done(
                _.bind(function() {
                    //debugging purposes
                    // _.each(controller.Collections.Message.models, function(message, index){
                    //   console.log("controller.Collections.Message[" + index + "].get('position'): ", message.get("position"), message.get("message") );
                    // })

                    var mergedData = controller.mergeFetchedData(
                        controller.Collections.ModerationOld
                    );

                    var messageIds = _.pluck(
                        controller.Collections.Message.toJSON(),
                        'id'
                    );
                    var newData = findByValues(
                        mergedData,
                        'id',
                        _.difference(_.pluck(mergedData, 'id'), messageIds)
                    );

                    // FOR DEBUGGING PURPOSES ONLY
                    // console.log("fetchModerationOld()-------------",newData);
                    // _.each(mergedData, function(message, index){
                    //   console.log("mergedData[" + index + "]: ", message.position +" | "+ message.message.slice(0,30));
                    // });

                    // FOR DEBUGGING PURPOSES ONLY
                    // _.each(controller.Collections.Message.models, function(message, index){
                    //   console.log("Message[" + index + "]: ", message.get('position') +" | "+ message.get('message').slice(0,30));
                    // });

                    if (!_.isEmpty(mergedData)) {
                        if (!_.isEqual(messageIds, _.pluck(mergedData, 'id'))) {
                            controller.Collections.Message.add(newData);
                            PubSub.trigger('mosaic:append', newData);
                            fetchingOldMessages = false;
                        } else {
                            holdFetchOld = true;
                            fetchingOldMessages = false;
                            // console.log("Moderation 2 FETCH: No Older Messages. You reached the end.");
                        }
                    } else {
                        fetchingOldMessages = false;
                        holdFetchOld = true;

                        // console.log("Moderation 2 FETCH: Returned Empty");
                    }
                })
            );
        },

        /**
         * [handleMessageReset description]
         * @param  {Backbone.Collection} collection [description]
         */
        handleMessageReset: function (collection) {
            if (collection.length > 0) {
                if (isInitLoad) PubSub.trigger('navigate', 'home');
                else controller.Routers.Router.home();
            } else if (controller.Collections.Moderation.models.length === 0) {
                PubSub.trigger('mosaic:fadeout', 'no-filter');
            } else {
                PubSub.trigger('mosaic:fadeout', 'empty');
            }
        },

        /**
         * [shareFacebook description]
         * @param  {Object} params [description]
         */
        shareFacebook: function(params) {
            controller.Models.Facebook.share(params.link);
        },

        /**
         * Scrolls the window to a specified target
         * @param {Element}  target   [description]
         * @param {Function} callback [description]
         */
        scrollToTarget: function(target, callback) {
            PubSub.trigger('feed-refresh', {
                type: 'pym:fanfeed:feed-refresh',
                message: {}
            });

            var target = target ? target : null;
            if (target && target.length) {
                $('html, body').animate(
                    {
                        scrollTop: target.offset().top
                    },
                    300,
                    function() {
                        if (callback) callback();
                    }
                );
            } else {
                $('html, body').animate(
                    {
                        scrollTop: 0
                    },
                    300,
                    function() {
                        if (callback) callback();
                    }
                );
            }
        },

        /**
         * Timer for user inactivity
         */
        resetTimer: function() {
            var mosaicInfo = controller.Models.Cms.get('text').view_mosaic;
            var rate =
                mosaicInfo.refresh_rate_seconds &&
                mosaicInfo.refresh_rate_seconds != ''
                    ? parseInt(mosaicInfo.refresh_rate_seconds) * 1000
                    : null;

            if (timer) {
                clearInterval(timer);
                timer = null;
            }

            var maxTime = rate ? rate : 60000;
            var count = 0;

            timer = setInterval(function() {
                // console.warn('setInterval called: ', count);
                if (count * 1000 >= maxTime) {
                    controller.fetchModeration();
                    count = 0;
                } else {
                    count++;
                    // console.log("count",count)
                }
            }, 1000);
        },

        stopTimer: function() {
            clearInterval(timer);
            timer = null;
        },

        setScrollFlag: function(isScrolling) {
            if (isScrolling === 'true') {
                this.isScrolling = true;
            } else {
                this.isScrolling = false;
                this.resetTimer();
            }
        },
        getUserIp: function(cb) {
            var isUploaderEnabled = isTrue(this.Models.Cms.get('text').view_uploader.show_uploader);

            if (!isUploaderEnabled) return;
            // add in a cachebuster for the ip fetching
            var cacheBuster =
                Math.floor(Math.random() * 10000) + new Date().getTime();

            $.ajax({
                url: '/getClientIp.php?' + cacheBuster,
                type: 'GET',
                dataType: 'json',
                data: {}
            }).done(function(data) {
                cb(data);
            });
        },

        remove: function() {
            this.Routers.Router.remove();
            this.stopTimer();

            var models = this.Models;
            for (var key in models) {
                if (Object.prototype.hasOwnProperty.call(models, key)) {
                    var model = models[key];
                    if( model ) {
                        if(typeof model.stopPolling === 'function') {
                            model.stopPolling();
                        }
                        model.off();
                        model.stopListening();
                        model = null;
                    }
                }
            }
        },

        filterInvalidMessages: function (newData = []) {
            if (newData.length == 0) return [];
            var validData = [];
            newData.forEach(function (value) {
                if (!this.Collections.InvalidMessage.get(value.id)) {
                    validData.push(value);
                }
            }.bind(controller))

            return validData;
        }
    };

    // define listeners
    PubSub.on('filtersChanged', controller.filtersChanged);
    PubSub.on('facebookShare', controller.shareFacebook);
    PubSub.on('fetch:old', controller.fetchModerationOld);
    PubSub.on('timer:reset', controller.resetTimer);
    PubSub.on('timer:stop', controller.stopTimer);
    PubSub.on('relay:scrollEvent', controller.setScrollFlag.bind(controller));

    var CmsMain = controller.Models.Cms = new ModelCms({
        wid: options.widget_id,
        updateFrequency: 30
    }, {});

    CmsMain.fetch().success(_.bind(widgetLoaded, this, controller, options));

    return controller;
}

function inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

function widgetLoaded(controller, options) {

    console.log('widgetLoaded');
    controller.isScrolling = false;

    // Akamai Geo Location
    controller.Models.Geo = new ModelGeo({
        countries: controller.Models.Cms.get('settings').countries
    });
    controller.Models.Geo.fetch().done(function() {
        // We need the geoCountry and geoState from Geo Model so we wait for it then set them in Flex Model
        var flexAttrs = {
            apiKey: controller.Models.Cms.get('settings').apiKey,
            topics: controller.Models.Cms.get('text')['view_uploader']
                .flex_topic_id
        };

        var rulesetCollectionId = controller.Models.Cms.get('text')[
            'view_uploader'
        ]['ruleset_collection_id'];

        if (rulesetCollectionId && !!parseInt(rulesetCollectionId)) {
            // flexAttrs[ "ruleset_collection_id" ]  = ruleset_collection_id;
            flexAttrs['ruleset_collection_ids'] = rulesetCollectionId;
        }

        controller.Models.Flex = new ModelFlex(flexAttrs, {
            version_id: controller.Models.Cms.get('settings').version_id,
            geoCountry: controller.Models.Geo.get('country'),
            geoState: controller.Models.Geo.get('geoheaders').region
        });

        controller.getUserIp(function(data) {
            controller.Models.Flex.set('userIp', data['ip-user']);
            controller.userIp = data['ip-user'];
            PubSub.trigger('userIp', data['ip-user']);
        });
    });

    controller.Models.Facebook = new ModelFacebook(
        {
            appId: controller.Models.Cms.get('social').fb.id
        },
        {
          version: 'v4.0',
          autoUpdateLoginStatus: false
        }
    );

    controller.Models.Vine = new ModelVine({}, {});

    controller.Models.Google = new ModelGoogle(
        {},
        {
            controller: controller,
            ga_id: controller.Models.Cms.get('settings').google_analytics,
            ga_domain: 'auto',
            useLocalStorage: inIframe()
        }
    );

    controller.Collections.Message = new CollectionMessage();
    controller.Collections.Moderation = new CollectionModeration();
    controller.Collections.ModerationOld = new CollectionModeration();
    controller.Collections.Message.on('reset', controller.handleMessageReset);
    controller.Collections.InvalidMessage = new CollectionInvalidMessage();
    controller.Collections.Filter = new CollectionFilter(
        controller.Models.Cms.get('data'),
        { wid: options.widget_id }
    );

    var pollRate = 30; // default
    var cmsPollRate = controller.Models.Cms.get('text').config.cms_poll_rate_in_seconds;

    if(cmsPollRate) {
      pollRate = parseInt(cmsPollRate, 10) < 3 ? 3 : parseInt(cmsPollRate, 10);
    }
    controller.Models.Cms.set({ updateFrequency: pollRate });

    controller.Models.Cms.on('change:data', controller.updateFilterCollection);
    controller.updateFilterCollection(
        controller.Models.Cms,
        controller.Models.Cms.get('data'),
        {}
    );

    controller.Models.Header = new ModelBase(
        {
            copy: _.clone(controller.Models.Cms.get('text').view_header, true),
            uid: options.uniqueId,
            filter: controller.Collections.Filter
        },
        { textKey: 'view_header', cms: controller.Models.Cms }
    );

    controller.Models.Page = new ModelBase(
        {
            copy: '',
            uid: options.uniqueId,
            header: controller.Models.Header
        },
        { cms: controller.Models.Cms }
    );

    // load twitter intents
    window.twttr = (function(d, s, id) {
        var t;
        var js;
        var fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://platform.twitter.com/widgets.js';
        fjs.parentNode.insertBefore(js, fjs);
        return (
            window.twttr ||
            (t = {
                _e: [],
                ready: function(f) {
                    t._e.push(f);
                }
            })
        );
    })(document, 'script', 'twitter-wjs');

    controller.Routers.Router = new (router({ hashState: options.hashState }))({
        el: options.container,
        modal: options.modal,
        uid: options.uniqueId,
        controller: controller
    });

    if (options.hashState) {
        Backbone.history.start();
    }
    if (!options.hashState) {
        controller.Routers.Router.navigate('home', { trigger: true });
    }
}

export default Controller;
