'use strict';
import Backbone from 'backbone';
import _ from 'lodash';

var GA_LOCAL_STORAGE_KEY = 'ga:clientId';

export default Backbone.Model.extend({
    defaults: {
        eventCategory: '',
        eventAction: '',
        eventLabel: '',
        windowStatus: -1,
        //default namespace for telescope apps
        namespace: 'telescopeinc'
    },
    /**
     * init function
     */
    initialize: function(attributes, options) {
        'use strict';
        try {
            if (_.isUndefined(options.ga_id)) {
                throw Error('Google Analytics ID must be provided');
            }
            if (_.isUndefined(options.ga_domain)) {
                throw Error('Google Analytics Domain must be provided');
            }
        } catch (e) {
            console.log(e);
        }

        this.ga_id = options.ga_id;
        this.ga_domain = options.ga_domain;

        if (options.windowStatus)
            this.set({ windowStatus: options.windowStatus });
        //custom namespaces appended to end of telescopeinc-
        if (options.namespace)
            this.set({ namespace: 'telescopeinc-' + options.namespace });


        this.useLocalStorage = options.useLocalStorage || false;

        //check if GA is initialized and if same name space has been initialized
        if (
            typeof ga !== 'undefined' &&
            typeof ga.getByName(this.get('namespace')) !== 'undefined'
        ) {
            //display warning if imnitialized and namespace already exists
            console.warn(
                'Google Analytics with same namespace has already been initialized.'
            );
        } else if (
            typeof ga !== 'undefined' &&
            typeof ga.getByName(this.get('namespace')) === 'undefined'
        ) {
            // don't load script but create ga with new namespace
            this.createGA();
        } else {
            // requires a ga_id
            this.loadScript();
        }

        _.bindAll(this, 'getWindowStage');
    },
    /**
     * Saves attribute data if it exists then calls function to send data
     * @param attr  Holds attributes to set. Optional.
     * @param options  Not used
     */
    save: function(attrs) {
        if (_.isUndefined(this.ga_id) || this.ga_id === '') {
            return;
        }
        attrs = attrs || {};
        this.set(attrs, { silent: true });
        this.sendGA();
    },
    /**
     * This uses the attributes to send data to google
     */
    sendGA: function() {
        if (_.isUndefined(this.ga_id) || this.ga_id === '') {
            return;
        }
        var category = this.get('eventCategory');
        var action = this.get('eventAction');
        var label = this.get('eventLabel') + ' : ' + this.getWindowStage();
        console.log('send', 'event', category, action, label);

        ga(this.get('namespace') + '.send', 'event', category, action, label);
    },
    /**
     * Older function that depended on passed in params to sendData
     * @param {getEvent}
     * @param {windowStatus} text to use
     * @param {addText} Text to add to action
     */
    log: function(getEvent, windowStatus, addText) {
        if (_.isUndefined(this.ga_id) || this.ga_id === '') {
            return;
        }
        var params = this.get(getEvent);

        if (_.isUndefined(params)) {
            params = {
                category: getEvent,
                action: getEvent
            };

            if (!_.isUndefined(addText)) {
                params.action += ' ' + addText;
            }

            if (!_.isUndefined(windowStatus)) {
                var windowStage = this.getWindowStage(windowStatus);
                params.action += ' : ' + windowStage;
            }

            params.label = params.action + ' : ' + this.device;

            console.log(
                ' (unmapped): getEvent=%o; params=%o;',
                getEvent,
                params
            );
        } else {
            params.action = params.category + ' : ' + params.name;
            if (params.addDescription && !_.isUndefined(addText)) {
                params.action += ' ' + addText;
            }

            if (params.useWindow && !_.isUndefined(windowStatus)) {
                var windowStage = this.getWindowStage(windowStatus);
                params.action += ' : ' + windowStage;
            }
            params.label = params.action + ' : ' + this.device;

            console.log('Google: getEvent=%o; params=%o;', getEvent, params);
        }

        console.log(
            '%o %o %o %o %o %o',
            'send',
            'event',
            params.category,
            params.action,
            params.label,
            this.get('ga_id')
        );
        ga(
            this.get('namespace') + '.send',
            'event',
            params.category,
            params.action,
            params.label
        );
    },
    /**
     * gets window status from attribute to determine window
     */
    getWindowStage: function() {
        var windowStage;

        switch (this.get('windowStatus')) {
            case 0:
                windowStage = 'window closed';
                break;
            case 1:
                windowStage = 'window open';
                break;
            default:
                windowStage = 'window unknown';
                break;
        }
        return windowStage;
    },
    /**
     * Loads google analytics script and then calls function to create ga object
     */
    loadScript: function() {
        if (_.isUndefined(this.ga_id) || this.ga_id === '') {
            return;
        }
        (function(i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            (i[r] =
                i[r] ||
                function() {
                    (i[r].q = i[r].q || []).push(arguments);
                }),
                (i[r].l = 1 * new Date());
            (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        })(
            window,
            document,
            'script',
            '//www.google-analytics.com/analytics.js',
            'ga'
        );
        this.createGA();
    },
    /**
     * Creates ga object with namespace name from attribute
     */
    createGA: function() {
        if (_.isUndefined(this.ga_id) || this.ga_id === '') {
            return;
        }

        if (window.localStorage && this.useLocalStorage) {
            ga('create', this.ga_id, this.ga_domain, {
                'name': this.get('namespace'),
                'storage': 'none',
                'clientId': localStorage.getItem(GA_LOCAL_STORAGE_KEY)
            });
            ga(function (tracker) {
                localStorage.setItem(GA_LOCAL_STORAGE_KEY, tracker.get('clientId'));
            });
        }
        else {
            ga('create', this.ga_id, this.ga_domain, { 'name': this.get('namespace') });
        }

        ga(this.get('namespace') + '.send', 'pageview');
    }
});
