'use strict';
import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';
import BackboneValidation from 'backbone-validation';

import template from './../templates/uploader.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';
import CSSView from './css';
import cssTemplate from './../templates/uploader-css.ejs';
import { emailHasher, isTrue } from '../util/helpers';
import { UPLOADER_VERSION_CHECK, VALID_VOTE, ACTION_TYPES } from '../util/constants';

var styleId;

export default BaseView.extend({
    template: template,
    tagName: 'div',
    className: 'view-uploader',
    /**
     * [events description]
     * @return {[type]} [description]
     */
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'submit .uploader-form': 'handleFormSubmit',
            'change select[name="state"]': 'onStateChange',
            'click .close': 'close'
        });
    },
    /**
     *
     */
    initialize: function(options) {
        _.bindAll(
            this,
            'render',
            'resetForm',
            'handleInvalid',
            'handleFieldValueChange',
            'handleModelSync'
        );
        console.log('FORM: initialize');

        BackboneValidation.bind(this, {
            model: this.model.get('model')
        });

        this.PII = this.model.get('model').fieldData.filter(function(field) {
            return isTrue(field.is_pii)
        }).map(function(field) {
            return field.id
        });

        this.listenTo(
            this.model.get('model'),
            'validated:invalid',
            this.handleInvalid
        );
        this.listenTo(
            this.model.get('model'),
            'validated:valid',
            this.handleValid
        );
        this.listenTo(
            this.model.get('model'),
            'change',
            this.handleFieldValueChange
        );

        //This is must be triggered manually in the router or wherever.
        this.listenTo(this.model.get('model'), 'sync', this.handleModelSync);

        styleId = this.className + _.uniqueId();
        this.$el.addClass(styleId);
        // this.listenTo(this.model, 'change:copy', this.render);

        this.flex = options.flex;
        this.tailor = options.tailor;
        this.connect = options.connect;

        this.listenTo(
            PubSub,
            'uploader:tailor:setup',
            _.bind(this.setupTailor, this)
        );

        this.setupTailor();
    },
    /**
     *
     */
    render: function() {
        console.log('FORM: render');
        if (this.CSSView) {
            this.CSSView.remove();
        }

        this.CSSView = new CSSView({
            model: this.model.get('copy').customizations,
            namespace: '#' + this.model.get('uid') + ' .' + styleId,
            template: cssTemplate
        });

        Backbone.$('head').append(this.CSSView.render().$el);

        var fields = this.model.get('model').getFieldData();
        var uniqueId = Math.round(Math.random() * 1000000);
        var requireCount = 0;
        _.each(
            fields,
            _.bind(function(field) {
                field.uniqueId = field.id + '_' + uniqueId;
                if (field.required === 'true') {
                    requireCount++;
                }
                if (
                    field.id === 'terms_and_conditions' &&
                    field.required === 'true'
                ) {
                    this.isTermRequired = true;
                } else {
                    this.isTermRequired = false;
                }
            }, this)
        );

        var params = {
            fields: fields
        };

        _.extend(params, this.model.get('copy'));

        this.$el.html(this.template(params));

        if (requireCount === 0) this.$('.submit').addClass('checked');
        this.setupFileUploadCombos();
        this.setupTextCounters();
        return this;
    },
    /**
     *
     */
    renderedToDom: function() {
        console.log('FORM: renderedToDOM');
        //Check for an error only on blur (unfocus).
        this.$('input,select,button').on(
            'change',
            _.bind(this.handleBlurValidation, this)
        );
        this.$('input,textarea').on(
            'keyup',
            _.bind(this.handleBlurValidation, this)
        );
        // FFD-166 limitCheck
        this.$('input[type="text"],textarea').on(
            'keyup',
            _.bind(this.limitCheck, this)
        );

        //If something is marked with .has-error, check constantly so we can clear the error message as soon as possible.
        this.$el.on(
            'change blur input',
            '.has-error :input,.has-error :file',
            _.bind(this.handleBlurValidation, this)
        );
    },
    /**
     *
     */
    setupTailor: function(newTailor) {
        this.stopListening(this.tailor);
        if (newTailor) this.tailor = newTailor;
        this.listenTo(
            this.tailor,
            'success:jobs',
            _.bind(function() {
                this.tailor.tailorUpload();
            }, this)
        );
        this.listenTo(
            this.tailor,
            'success:upload',
            _.bind(function() {
                this.tailor.tailorStart();
            }, this)
        );
        // this.listenTo(this.tailor, 'error', _.bind(this.onTailorError, this));
    },
    /**
     *
     */
    onTailorError: function(data, model, res) {
        console.warn('Tailor Error:', res.responseText);

        this.connect.save({ action_type: ACTION_TYPES.INVALID, hashed: data.hashed });

        var errors = {};
        errors['upload'] = res.responseJSON.messages[0];
        $('.file-upload-error').show();
        this.$('.upload-gif').hide();
        this.renderErrors(errors);
        this.enableForm();

        this.stopListening(this.tailor);
        PubSub.trigger('uploader:tailor:init');
    },
    /**
     *
     */
    handleFieldValueChange: function() {
        console.log('FORM: handleFieldValueChange');
        var changed = this.model.get('model').changedAttributes();

        _.each(
            changed,
            _.bind(function(value, key) {
                var field = this.$('[name="' + key + '"]');
                var type = field.prop('type');

                switch (type) {
                    case 'file':
                        this.clearFileUpload(field);
                        break;
                    case 'checkbox':
                        field.prop('checked', value === true);
                        break;
                    default:
                        field.val(value);
                }
            }, this)
        );
    },
    /**
     *
     */
    handleFormSubmit: function(e) {
        console.log('FORM: handleFormSubmit', e);
        e.preventDefault();

        var params = {};
        var form = this.$('.uploader-form');
        _.each(form.serializeArray(), function(data) {
            params[data.name] = data.value;
        });

        _.each(form.find('input:checkbox'), function(data) {
            params[data.name] = data.checked ? 'true' : 'false';
        });

        _.each(this.$('input[type="file"]'), function(data) {
            params[data.name] = data.value;
        });

        this.model.get('model').set(params, { silent: true });

        this.disableForm();
        this.model.get('model').save();
    },
    /**
     *
     */
    disableForm: function() {
        console.log('FORM: disableForm');
        this.$('form :input').attr('disabled', true);
    },

    /**
     *
     */
    enableForm: function() {
        console.log('FORM: disableForm');
        this.$('form :input').attr('disabled', false);
        this.$('.file-combo-text input').attr('disabled', true);
    },
    /**
     *
     */
    handleBlurValidation: function(e) {
        console.log('FORM: handleBlurValidation');
        var field = $(e.target);
        var value = field.val();
        if (field.prop('type').toLowerCase() === 'checkbox') {
            value = field.is(':checked');
        }
        var error = this.model
            .get('model')
            .preValidate(field.prop('name'), value);

        if (error) {
            if (field.prop('type').toLowerCase() === 'file' && value === '') {
                $('.file-upload-error').hide();
                $('.file-upload-added').hide();
                return;
            }
            var errors = {};
            errors[field.prop('name')] = error;
            this.renderErrors(errors);
            this.$('.submit').removeClass('checked');
        } else {
            field
                .closest('.form-group')
                .removeClass('has-error')
                .find('.error-message')
                .remove();
            if (field.prop('type').toLowerCase() === 'file') {
                if (value === '') {
                    $('.file-upload-error').hide();
                    $('.file-upload-added').hide();
                    this.$('.submit').removeClass('checked');
                } else {
                    $('.file-upload-error').hide();
                    $('.file-upload-added').show();
                    this.$('.submit').addClass('checked');
                }
            } else {
                this.$('.submit').addClass('checked');
            }
        }
    },
    /**
     *
     */
    handleInvalid: function(model, errors) {
        console.log('FORM: handleInvalid');
        $('.has-error')
            .removeClass('has-error')
            .find('.error-message')
            .remove();

        this.enableForm();
        this.renderErrors(errors);
    },
    /**
     *
     */
    handleValid: function(model) {
        console.log('FORM: handleValid');

        $('.has-error')
            .removeClass('has-error')
            .find('.error-message')
            .remove();
        this.$('.upload-gif').show();
        $('.file-upload-error').hide();

        var data = model.attributes;
        var privateData = {};
        var publicData = {};
        
        data.user_name = data.display_name || data.username || data.screen_name || 'unknown';
        
        data.hashed = emailHasher(data.email, this.connect.version_id, UPLOADER_VERSION_CHECK);

        var pii = this.PII;
        Object.keys(data).forEach(function(key) {
            if (pii.indexOf(key) >= 0) {
                privateData[key] = data[key]
            } else {
                publicData[key] = data[key]
            }
        });
        
        // send private data to voteapi
        this.listenTo(this.connect, 'error',  _.bind(this.onVoteError, this));
        this.listenTo(this.connect, 'sync',  _.bind(this.onVoteSuccess, this, publicData));
        
        // Send hash to private data to match flex's
        privateData.hashed = data.hashed;
        
        this.connect.save(_.extend(privateData, { action_type: ACTION_TYPES.VOTE }));
    },
        
    handleUpload: function(data) {
        if (data.upload && data.upload != '') {
            // form has file upload (invalid files are detected in validation and wouldn't go in this function)

            this.file = {
                file: [$('.uploader-file-input')[0].files[0]],
                name: $('.uploader-file-input')[0].files[0].name
            };

            console.log('FILE UPLOAD INFO', this.file);

            this.tailor.set('files', this.file.file);
            this.tailor.setUpData(data);
            this.listenTo(this.tailor, 'error', _.bind(this.onTailorError, this, data));
            this.tailor.save();
        } else {
            // form has no file upload (using flex instead of tailor)

            this.flex.setUpData(data);
            this.listenTo(this.flex, 'sync', _.bind(this.onFlexSync, this));
            this.listenTo(this.flex, 'error', _.bind(this.onFlexError, this));
            this.flex.save();
        }
    },

    onVoteSuccess: function(data, response, model) {
        this.stopListening(model);
        if (response && (response.response_code === VALID_VOTE) ) {
            const topicId = isNaN(response.topic_id) ? 0 : +(response.topic_id);
            this.tailor.updateTopicId(topicId);
            this.handleUpload(data);
        } else {
            this.onVoteError();
        }
    },

    onVoteError: function(model) {
        this.stopListening(model);
        // All vote responses trigger the thanks modal
        // UI won't handle vote error
        // If vote is not valid, do not upload content to Moderation/ Flex 
        PubSub.trigger('navigate', 'uploader-thanks');
    },

    onFlexSync: function(model) {
        this.stopListening(model);
        console.log('FLEX SUCCESS');
        if (model.get('flex_message') || model.get('job_id') !== undefined) {
            PubSub.trigger('navigate', 'uploader-thanks');
        } else {
            // this.error("upload", model);
            // TODO: trigger an error modal for this
        }
    },

    onFlexError: function(model) {
        this.stopListening(model);
        console.log('FLEX ERROR');
        // TODO: trigger an error modal for this
    },

    /**
     *
     */
    renderErrors: function(errors) {
        console.log('FORM: renderErrors');
        _.each(errors, function(error, name) {
            var el = $('<div class="error-message">' + error + '</div>');
            var parent = $('[name="' + name + '"]').closest('.form-group');

            if (name === 'upload' && $('input[type="file"]').val() != '') {
                $('.file-upload-added').hide();
                $('.file-upload-error').show();
            }

            if (
                parent.addClass('has-error').find('.error-message').length === 0
            ) {
                parent.append(el);
            }
        });
    },
    /**
     *
     */
    setupFileUploadCombos: function() {
        console.log('FORM: setupFileUploadCombos');
        this.$('.file-combo input[type="file"]').change(
            _.bind(this.handleFileUploadChange, this)
        );
        this.$('.file-combo .btn-close')
            .click(_.bind(this.handleFileUploadClear, this))
            .hide();
    },
    /**
     * [handleFileUploadChange description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    handleFileUploadChange: function(e) {
        console.log('FORM: handleFileUploadChange');
        // var file = $(e.currentTarget);
        // var path = file.val();
        // var name = path.replace(/.*(\/|\\)/, '');
        // var close = file.closest('.file-combo').find('.btn-close');
        // file.closest('.file-combo').find('input[type="text"]').val(name);
        // $('.file-combo-text').append()
        // if (path === '') {
        //     close.hide();
        // } else {
        //     close.show();
        // }

        //Firefox needs this.
        this.handleBlurValidation(e);
    },
    /**
     * [clearFileUpload description]
     * @param  {[type]} fileElement [description]
     * @return {[type]}             [description]
     */
    clearFileUpload: function(fileElement) {
        console.log('FORM: clearFileUpload');
        var btn = $(fileElement);
        var fileCombo = btn.closest('.file-combo');
        var file = fileCombo.find('input[type="file"]').get(0);

        $(file)
            .wrap('<form>')
            .closest('form')
            .get(0)
            .reset();
        $(file).unwrap();

        fileCombo.find('input[type="text"]').val('');
        fileCombo.find('.btn-close').hide();
    },
    /**
     * [handleFileUploadClear description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    handleFileUploadClear: function(e) {
        console.log('FORM: handleFileUploadClear');
        this.clearFileUpload(e.currentTarget);
    },
    /**
     * Sets up listeners for all of the character counters on the page.
     */
    setupTextCounters: function() {
        console.log('FORM: setupTextCounters');
        this.$('.has-counter input, .has-counter textarea')
            .on(
                'change keyup input propertychange',
                _.bind(this.onCounterUpdate, this)
            )
            .focus(function() {
                $(this)
                    .parent()
                    .addClass('input-group-focus');
            })
            .blur(function() {
                $(this)
                    .parent()
                    .removeClass('input-group-focus');
            });
    },
    /**
     *
     */
    onCounterUpdate: function(e) {
        console.log('FORM: onCounterUpdate');
        var field = $(e.currentTarget);
        var len = field.val().length;
        var max = parseInt(e.currentTarget.getAttribute('data-maxlength'), 10);
        var counter = $(e.currentTarget).next('.input-group-counter');
        if (_.isFinite(max)) {
            counter.text(Math.abs(max - len));
        }
    },
    /**
     * [handleModelSync description]
     * @param  {[type]} model [description]
     * @return {[type]}       [description]
     */
    handleModelSync: function() {
        console.log('FORM: handleModelSync');
        var fieldOptions = this.model.get('model').getFieldData();
        var attrs = {};

        for (var i = 0; i < fieldOptions.length; i++) {
            var field = fieldOptions[i];
            if (field.clear_on_submit === 'true') {
                if (field.type === 'checkbox') {
                    attrs[field.id] = false;
                } else {
                    attrs[field.id] = '';
                }
            }
        }
        this.model.get('model').set(attrs);

        this.enableForm();
    },
    /**
     *
     */
    resetForm: function(e) {
        console.log('FORM: resetForm');
        if (e) {
            e.preventDefault();
        }
        this.$('.file-combo .btn-close').hide();

        // this.$('.uploader-form').reset();
        this.$('.uploader-form')[0].reset();
        this.$('.checkbox, label').removeClass('active');
    },
    /**
     *
     */
    remove: function() {
        console.log('FORM: remove');
        if (this.CSSView) {
            this.CSSView.remove();
        }

        this.$('input,textarea,select,button').off(
            'change keyup input propertychange'
        );

        return Backbone.View.prototype.remove.call(this);
    },
    /**
     * [onStateChange description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    onStateChange: function(e) {
        console.log('FORM: onStateChange', e);

        var val = this.$(e.currentTarget).val();

        var otherRegionValidation = _.findWhere(this.model.fieldData, {
            id: 'other_region'
        });

        otherRegionValidation.required =
            val.toLowerCase() === 'n/a' ? 'true' : 'false';

        this.validateField(this.$('[name="other_region"]'));
    },
    /**
     * [checkboxChange description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    checkboxChange: function(e) {
        if (
            e.currentTarget.name === 'terms_and_conditions' &&
            this.isTermRequired
        )
            this.$('.submit').toggleClass('checked');
    },
    /**
     * [limitCheck description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    limitCheck: function(e) {
        var elMaxLength = parseInt(
            e.currentTarget.getAttribute('data-maxlength'),
            10
        );
        console.log('FFD-166', elMaxLength, e.currentTarget.value.length);
        if (
            _.isFinite(elMaxLength) &&
            e.currentTarget.value.length > elMaxLength
        ) {
            e.currentTarget.value = e.currentTarget.value.substring(
                0,
                elMaxLength
            );
            console.log(
                'FFD-166',
                'current value > elMaxLength',
                e.currentTarget.value.length
            );
            e.preventDefault();
            this.handleBlurValidation(e);
            this.onCounterUpdate(e);
        }
    },
    /**
     * [close description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    close: function(e) {
        console.log('close');
        if (e) {
            e.preventDefault();
        }
        // PubSub.trigger('navigate', 'home');
        PubSub.trigger('modals:close');
    }
});
