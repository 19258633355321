'use strict';
import _ from 'lodash';
import Backbone from 'backbone';
import Model from './../models/message';
import PubSub from '../PubSub';

export default Backbone.Collection.extend({
  url: '/data/getMessages-mixed.json',
  model: Model,
  comparator: function(a, b) {
    var direction = 1; // Asc
    //var direction = -1; // Desc
    a = a.get('position');
    b = b.get('position');
    return direction * a.localeCompare(b);
  },
  initialize: function() {
    this.listenTo(PubSub, 'tile:remove', this.onMessageLoadError.bind(this));
  },
  /**
   * [sync description]
   * @param  {[type]} method  [description]
   * @param  {[type]} model   [description]
   * @param  {[type]} options [description]
   * @return {[type]}         [description]
   */
  sync: function(method, model, options) {
    
    var params = {
      url: this.url,
      dataType: 'json',
      crossDomain: true
    };

    _.extend(params, options);

    this.xhr = Backbone.sync(method, model, params)
      .success(_.bind(this.syncSuccess, this))
      .fail(_.bind(this.syncError, this));

    return this.xhr;
  },
  /**
   * [syncSuccess description]
   * @param  {[type]} response [description]
   * @return {[type]}          [description]
   */
  syncSuccess: function(response) {
    console.log('syncSuccess', response);
  },
  /**
   * [syncError description]
   * @param  {[type]} response [description]
   * @return {[type]}          [description]
   */
  syncError: function(response) {
    console.log('syncError', response);
  },
  /**
   * [parse description]
   * @param  {[type]} response [description]
   * @param  {[type]} options  [description]
   * @return {[type]}          [description]
   */
  parse: function(response) {
    console.log('Message Collection parse', response, _.values(response));
    return _.values(response);
  },
  /**
   * Helper function for marking which Messages are removed from the current Mosaic View
   * Note: A message is removed if its image or video source results in a 404 due to a
   * user deleting his/her post from the social media platform (instagram, twitter, facebook)
   * @param {string} id - model id
   */
  onMessageLoadError: function (id = '') {
    const found = this.findWhere({ id: id });
    if (found) found.set({ isRemoved: true }, {silent: true});
  },
  /**
   * returns the count of the total amount of removed messages from the mosaic
   */
  countRemoved: function () {
    return this.models.reduce(function (acc, currentValue) {
      if (currentValue.get('isRemoved')) return ++acc;
      return acc;
    }, 0);
  }
});
