'use strict';
import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';

import template from './../templates/mosaic.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';
import CSSView from './css';
var styleId;
import TileView from './tile';
import TileAdView from './tile-ad';
import cssTemplate from './../templates/mosaic-css.ejs';
import { isTrue } from '../util/helpers';

const MAX_MESSAGES_COUNT = 100;

export default BaseView.extend({
    template: template,
    tagName: 'div',
    className: 'view-mosaic',
    /**
     * [events description]
     * @return {[type]} [description]
     */
    events: function () {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click [data-load-more-posts]': 'loadTiles',
            'click [data-scroll-to-new-posts]': 'handleNewPostsClick',
        });
    },
    /**
     * [initialize description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    initialize: function () {
        _.bindAll(this, 'render');

        styleId = this.className + _.uniqueId();
        this.$el.addClass(styleId);
        this.listenTo(this.model, 'change:copy', this.render);

        this.leftOverTiles = [];
        this.ads = _.where(this.model.get('copy').ads.units, { active: '1' });
        this.tileFreqCtr = 0;
        this.topicFilters = this.model.get('topic_filters');
        this.typeFilters = this.model.get('type_filters');
        this.intersectionObserver = null;
        this.newPosts = [];
        // this.messages = this.model.get('messages');
        // this.listenTo(this.messages, 'change', _.bind(this.render, this));

        this.displayLoadMoreButton = isTrue(
            this.model.get('copy').buttons.load_more.settings.display
        );
        var amountPerClick = parseInt(
            this.model.get('copy').buttons.load_more.amount_per_click,
            10
        );
        this.countToLoad = isNaN(amountPerClick)
            ? MAX_MESSAGES_COUNT
            : Math.min(MAX_MESSAGES_COUNT, amountPerClick);

        this.listenTo(PubSub, 'mosaic:update:topic', this.mosaicUpdateTopic.bind(this));
        this.listenTo(PubSub, 'mosaic:update:type', this.mosaicUpdateType.bind(this));
        this.listenTo(PubSub, 'mosaic:fadeout', this.mosaicFadeOut.bind(this));

        if (!this.model.get('numTileLimit')) {
            // if there is not max limit, append new messages to the mosaic
            this.listenTo(
                PubSub,
                'mosaic:append',
                function (messages) {
                    console.log('Append Messages To Mosaic', messages);
                    this.generateTiles(messages, this.appendData.bind(this));
                }.bind(this)
            );

            this.listenTo(
                PubSub,
                'mosaic:prepend',
                function (messages) {
                    console.log('Prepend Messages To Mosaic', messages);
                    this.newPosts = messages.concat(this.newPosts);
                    console.log('DP-26765', this.newPosts);
                    this.showNewPostsButton();
                }.bind(this)
            );
        }

        this.listenTo(
            PubSub,
            'reset:tiles',
            function () {
                this.leftOverTiles = [];
                console.log(
                    'filter change, leftover tiles reset',
                    this.leftOverTiles
                );
            }.bind(this)
        );

        this.itemHeight = 0;
        Backbone.$(window).on(
            'resize',
            _.bind(function () {
                this.itemHeight = 0;
            }, this)
        );
    },
    startIntersectionObserver: function () {
        this.intersectionObserver = new IntersectionObserver(
            this.handleIntersection.bind(this),
            {
                threshold: [1],
            }
        );
        var target = this.$el[0].querySelector('#feed-container').firstChild;

        if (target) {
            this.intersectionObserver.observe(target);
        }
    },
    handleIntersection: function (entries) {
        console.log('handleIntersection()');
        var button = this.$('#new-posts');
        if (!button || this.newPosts.length == 0) return;

        if (entries[0]['isIntersecting']) {
            button.addClass('static');
        } else {
            button.removeClass('visually-hidden');
            button.removeClass('static');
        }
    },
    handleNewPostsClick: function () {
        this.renderNewerTiles();
        $('html, body').animate({ scrollTop: 0 }, 0);
        this.newPosts = [];
        this.hideNewPostsButton();
    },
    renderedToDom: function () {
        this.startIntersectionObserver();
    },
    showNewPostsButton: function () {
        var button = this.$('#new-posts');
        if (!button) return;
        button.removeClass('visually-hidden');
    },
    hideNewPostsButton: function () {
        var button = this.$('#new-posts');
        if (!button) return;
        button.addClass('visually-hidden');
    },
    /**
     * [render description]
     * @return {[type]} [description]
     */
    render: function () {
        this.itemHeight = 0;

        if (this.CSSView) {
            this.CSSView.remove();
        }

        this.CSSView = new CSSView({
            model: this.model.get('copy')['customizations'],
            namespace: '#' + this.model.get('uid') + ' .' + styleId,
            template: cssTemplate,
        });

        Backbone.$('head').append(this.CSSView.render().$el);

        var messages = this.model.get('messages').toJSON();

        console.log('mosaic view', _.result(this, 'events'), messages);

        this.$el.html(
            this.template(
                _.extend(this.model.toJSON(), {
                    displayLoadMoreButton: this.displayLoadMoreButton,
                    displayNewPostsButton: true,
                })
            )
        );

        this.generateTiles(messages, this.appendInit.bind(this));

        return this;
    },

    generateTiles: function (messages, callback) {
        var adFrequency = parseInt(this.model.get('copy').ads.frequency, 10);
        var tiles = (this.tiles = []);
        var numTileLimit = this.model.get('numTileLimit');

        console.log('GENERATE TILES', messages.length);

        if (messages.length === 0) return;

        if (numTileLimit && numTileLimit > 0) {
            messages = messages.slice(0, numTileLimit);
        }
        _.forEach(
            messages,
            _.bind(function (item) {
                var tileType = this.getTileType(item);
                if (_.indexOf(this.typeFilters, tileType) > -1) {
                    var newTile = new TileView({
                        model: {
                            type: tileType,
                            data: item,
                            copy: this.model.get('copy'),
                        },
                        className:
                            TileView.prototype.className +
                            ' view-tile-' +
                            tileType +
                            ' filter-' +
                            item.filter_tag +
                            ' source-' +
                            item.source,
                    }).render().$el;

                    tiles.push(newTile);
                    this.tileFreqCtr++;

                    if (this.ads.length === 0) {
                        this.ads = _.where(this.model.get('copy').ads.units, {
                            active: '1',
                        });
                    }

                    // if((index+1)%adFrequency===0 && this.ads.length>0){
                    if (
                        this.tileFreqCtr === adFrequency &&
                        this.ads.length > 0 &&
                        adFrequency > 0
                    ) {
                        tiles.push(
                            new TileAdView({
                                model: {
                                    type: 'ad',
                                    data: this.ads.shift(),
                                },
                                className:
                                    TileAdView.prototype.className +
                                    ' ad-tile-' +
                                    tileType +
                                    ' filter-' +
                                    item.filter_tag,
                            }).render().$el
                        );
                        this.tileFreqCtr = 0;
                    }
                }
            }, this)
        );

        if (callback) callback(tiles);
    },

    getTileType: function (item) {
        if (/(contains_video)/i.test(item.contains)) {
            return 'video';
        } else if (/(contains_image)/i.test(item.contains)) {
            return 'image';
        } else if (item.message.length > 0) {
            return 'text';
        } else {
            return null;
        }
    },

    appendInit: function (tiles) {
        console.log('GENERATE APPEND INIT', tiles);

        if (tiles.length === 0) {
            setTimeout(function () {
                PubSub.trigger('empty');
            }, 300);
            return;
        }

        var initCount =
            this.countToLoad > tiles.length ? tiles.length : this.countToLoad;
        this.leftOverTiles = tiles.slice(initCount);
        this.displayedTiles = tiles.slice(0, initCount);
        this.renderTiles(this.displayedTiles);

        // console.log("GENERATE SPLICED",this.displayedTiles,this.leftOverTiles);

        this.$('.tile-list-wrapper').append(this.displayedTiles);
        this.animateTiles(500);
    },

    appendData: function (tiles) {
        console.log('leftover', this.leftOverTiles, tiles);
        var leftover = this.leftOverTiles;

        if (this.leftOverTiles.length == 0) {
            this.leftOverTiles = tiles;
        } else {
            // this.leftOverTiles = leftover.concat(tiles);
            this.leftOverTiles = tiles.concat(leftover);
        }
        console.log('leftover after union', this.leftOverTiles);
        this.scrolling();
        this.handleLoadMoreVisibility();
    },

    scrolling: function () {
        this.$body = $(document.body);
        //if page is scrollable:
        if (this.$body.css('overflow').toLowerCase() != 'fixed') {
            if (
                $(window).scrollTop() + $(window).height() >
                $('body').height() - 200
            ) {
                // when scroll to bottom of the page
                console.log('page scrolling BOTTOM FETCH');

                if (this.leftOverTiles.length > 0) {
                    console.log('LEFTOVERS FETCH');
                    var arr = this.leftOverTiles.slice(0, 6);
                    // this.displayedTiles.concat(arr);
                    this.leftOverTiles = this.leftOverTiles.slice(6);

                    this.$('.tile-list-wrapper').append(arr);
                    this.animateTiles(500, this.scrolling);

                } else {
                    console.log('NO LEFTOVERS FETCH');
                    PubSub.trigger('fetch:old');
                }
            }
        } else if (this.$body.css('overflow').toLowerCase() == 'hidden') {
        }
    },
    handleLoadMoreVisibility: function () {
      var button = this.$('.load-more');
      if (this.leftOverTiles.length === 0) {
        button && button.hide();
      } else {
        button && button.show();
      }
    },
    loadTiles: function () {
        console.log('load tiles');
        if (this.leftOverTiles.length > 0) {
            var arr = this.leftOverTiles.slice(0, this.countToLoad);
            // this.displayedTiles.concat(arr);
            this.leftOverTiles = this.leftOverTiles.slice(this.countToLoad);
            // preemptively fetches more posts if the next batch is less than what we need to render
            if (this.leftOverTiles.length < this.countToLoad) {
                PubSub.trigger('fetch:old');
            }
            this.$('.tile-list-wrapper').append(arr);
            this.animateTiles(500, this.scrolling);

            // PubSub.trigger("pym:scroll:parent");
        } else {
            PubSub.trigger('fetch:old');
        }
        this.handleLoadMoreVisibility();
    },

    animateTiles: function (speed, callback) {
        var hiddenTiles = this.$('.invisible');
        if (hiddenTiles.length > 0) {
            for (var i = 0; i < hiddenTiles.length; i++) {
                _.delay(
                    _.bind(function (k) {
                        $(hiddenTiles[k])
                            .animate({ opacity: 1 }, 500)
                            .addClass('scale-in')
                            .removeClass('invisible');
                        if (hiddenTiles.length === k + 1 && callback)
                            _.bind(callback, this);
                    }, this),
                    (i * speed) / 4,
                    i
                );
            }
        }
    },

    mosaicUpdateTopic: function (params) {
        // currently not used

        var filterID = params.filterID;
        var action = params.action;

        if (action === 'show') {
            // $('.filter-'+filterID).fadeIn(500);

            // // also show the remaining leftover tiles
            // _.forEach(this.leftOverTiles, _.bind(function(tile){

            //   if($(tile).hasClass('filter-'+filterID)) {
            //     $(tile).show();
            //   }

            // },this));

            PubSub.trigger('mosaic:refresh');
        } else if (action === 'hide') {
            $('.filter-' + filterID).fadeOut(500);

            // also hide the remaining leftover tiles
            _.forEach(
                this.leftOverTiles,
                _.bind(function (tile) {
                    if ($(tile).hasClass('filter-' + filterID)) {
                        $(tile).hide();
                    }
                }, this)
            );
        }
    },

    mosaicUpdateType: function (params) {
        // currently not used

        var filterType = params.filterType;
        var action = params.action;

        if (action === 'show') {
            // $('.view-tile-'+filterType).fadeIn(500);
            // $('.ad-tile-'+filterType).fadeIn(500);
            // // also show the remaining leftover tiles
            // _.forEach(this.leftOverTiles, _.bind(function(tile){
            //   if($(tile).hasClass('view-tile-'+filterType) || $(tile).hasClass('view-tile-'+filterType)) {
            //     $(tile).show();
            //   }
            // },this));
        } else if (action === 'hide') {
            $('.view-tile-' + filterType).fadeOut(500);
            $('.ad-tile-' + filterType).fadeOut(500);

            // also hide the remaining leftover tiles
            _.forEach(
                this.leftOverTiles,
                _.bind(function (tile) {
                    if (
                        $(tile).hasClass('view-tile-' + filterType) ||
                        $(tile).hasClass('view-tile-' + filterType)
                    ) {
                        $(tile).hide();
                    }
                }, this)
            );
        } else {
            return;
        }
    },

    mosaicFadeOut: function (route) {
        var allTiles = $('.view-tile');

        if (allTiles.length > 0) {
            _.forEach(
                allTiles,
                _.bind(function (tile, index) {
                    if (index % 3 === 0) {
                        $(tile).addClass('tile-sub-one');
                    } else {
                        $(tile).addClass('tile-sub-two');
                    }
                }, this)
            );

            $('.tile-sub-one').addClass('minimize-one');
            $('.tile-sub-two').addClass('minimize-two');

            $(allTiles).fadeOut(
                400,
                _.bind(function () {
                    if (route) PubSub.trigger(route);
                }, this)
            );
        } else {
            if (route) PubSub.trigger(route);
        }
    },
    /**
     * renderTiles
     * Takes an array of tiles and appends or prepends them to the feed
     *
     */
    renderTiles: function (tiles, callback, isAppend = true) {
        if (isAppend) {
            this.$('[data-feed]').append(tiles);
        } else {
            this.$('[data-feed]').prepend(tiles);
        }
        this.animateTiles(500, callback);
    },
    renderNewerTiles: function () {
        this.generateTiles(this.newPosts, this.prependData.bind(this));
    },

    prependData: function (tiles) {
        this.renderTiles(tiles, null, false);
    },
    remove: function () {
        this.stopListening();
        this.intersectionObserver.disconnect();
    },
});
