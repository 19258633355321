'use strict';
import $ from 'jquery';
import Backbone from 'backbone';
Backbone.$ = $;

import BackboneLocalStorage from 'backbone.localstorage';
Backbone.LocalStorage = BackboneLocalStorage;

// relative utils
import './util/plugin';

// styles
import '../styles/main.scss';

$.noConflict(true);
Backbone.noConflict();

import Widget from './Widget';
const WidgetClassName = 'telescopewidgetfanfeed';

/**
 * @param options
 * {
 * widget_id: 'data/data.json' // REQUIRED string
 * container: $('#myWidget') // REQUIRED string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 * modal: $('#wrapper') // string || jQuery selector (http://api.jquery.com/jQuery/) || element reference (https://developer.mozilla.org/en-US/docs/Web/API/element)
 * uniqueId: 'name-type-namespace-rev' // string
 * cssUrl: 'http://a1.telesocpe.tv/styles/main.css' // absolute path
 * cssIsUnique: true // boolean - if true css should use an ID selector for the namespacing, otherwise use a class selector for the namespacing and restrict loading and injection of style block to a single execution
 * hashState: false // boolean - when true, enables hashable routes **for testing - only set to true when instantiation of the widget is limited to one object per frameElement/window
 * endpoints: {cms: '//stateapi-prod.votenow.tv/widgets/get'} // object
 * }
 */
export function FanFeed(options){

    // no hashed routes on initialize
    if(window.location.href.indexOf('#') !== -1 && window.location.search.indexOf('devmode')===-1){
        window.location = window.location.href.split('#')[0];
    }

    var options = options || {};

    options.container = $(options.container || document.body) ;
    options.modal = $(options.modal || options.container);

    options.cssIsUnique = (typeof options.cssIsUnique === 'undefined'? true : options.cssIsUnique);

    options.uniqueId = options.uniqueId
        || $(options.container).attr("id")
        || (function(){
        var retval = WidgetClassName;
        if(options.widget_id) {
            retval += "-" + options.widget_id + "-";
        }
        retval += (Date.now() % 1e6);
        return retval;
    }());

    if(!$(options.uniqueId).length) {
        $(options.container).attr('id', options.uniqueId);
    }

    new Widget(options);

};
