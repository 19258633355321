'use strict';
import _ from 'lodash';
import Backbone from 'backbone';
import template from './../templates/css.ejs';

export default Backbone.View.extend({
    tagName: 'style',
    className: 'view-css',
    initialize: function(options) {
        this.template = options.template ? options.template : template;
        this.namespace = options.namespace || '';
        this.styleId = options.styleId || '';
        this.modal = options.modal;

        this.model = _.extend(_.clone(this.model, 'true'), {
            namespace: this.namespace,
            styleId: this.styleId,
            modal: this.modal
        });

        this.$el.prop('type', 'text/css');
    },
    /**
     *
     */
    render: function() {
        this.$el.append(this.template(this.model));
        return this;
    },
    remove: function() {
        this.template = null;
        this.namespace = null;
        this.model = null;
        this.off();
        Backbone.View.prototype.remove.call(this);
    }
});
