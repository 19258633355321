'use strict';
import _ from 'lodash';

import template from './../templates/modal.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';

export default BaseView.extend({
    template: template,
    tagName: 'div',
    className: 'view-modal',
    events: {
        'click .modal-window': 'onClickOut'
    },

    /**
     * [initialize description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    initialize: function() {
        _.bindAll(this, 'render');
    },
    /**
     * [render description]
     * @return {[type]} [description]
     */
    render: function() {
        this.$el.html(this.template());

        //test for touch events support and if not supported, attach .no-touch class
        this.$el.addClass(
            !('ontouchstart' in document.documentElement) ? 'no-touch' : ''
        );
        this.$('.modal-content').append(
            _.isObject(this.model.view)
                ? this.model.view.render().$el
                : this.model.view
        );

        return this;
    },
    /**
     *
     */
    onClickOut: function(e) {
        if (
            e.target.className === 'modal-window' ||
            e.target.className === 'modal-layout'
        ) {
            PubSub.trigger('modals:close');
            PubSub.trigger('timer:reset');
        }
    }
});
