'use strict';
import _ from 'lodash';
import Backbone from 'backbone';

import template from './../templates/header.ejs';
import BaseView from './../views/base';
import ViewAd from './../views/ad';
import ViewFilter from './../views/filter';
import PubSub from './../PubSub';
import CSSView from './css';
var styleId;

import cssTemplate from './../templates/header-css.ejs';
import { isTrue } from '../util/helpers';


export default BaseView.extend({
    template: template,
    className: 'view-header',
    /**
     * [events description]
     * @return {[type]} [description]
     */
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .btn-upload': 'uploader',
            'click .btn-facebook': 'shareToFacebook'
        });
    },
    /**
     * [initialize description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    initialize: function(options) {
        _.bindAll(this, 'render');

        options = options || {};

        styleId = this.className + _.uniqueId();
        this.$el.addClass(styleId);
        this.listenTo(this.model, 'change:copy', this.render);
        this.listenTo(
            this.model.get('filter').get('data'),
            'update',
            this.render
        );
        PubSub.on('header:render', _.bind(this.render, this));

        this.model = options.model;
        this.uploader = options.uploader;
    },
    /**
     * [render description]
     * @return {[type]} [description]
     */
    render: function() {
        if (this.CSSView) {
            this.CSSView.remove();
        }

        var useBgAsImage = isTrue(this.model.get('copy')['background']['use_as_image']);
        if (useBgAsImage) {
            this.$el.addClass('with-image')
        };

        var bg = this.model.get('copy')['background'];
        var headerCustoms = this.model.get('copy')['customizations'];
        headerCustoms.bg = {...bg, useBgAsImage};

        this.CSSView = new CSSView({
            model: headerCustoms,
            namespace: '#' + this.model.get('uid') + ' .' + styleId,
            template: cssTemplate
        });

        Backbone.$('head').append(this.CSSView.render().$el);

        var bgCss = {
            'background-color': bg.color.length > 0 ? bg.color : 'transparent'
        };

        var modelCopy = this.model.get('copy');
        modelCopy.show_uploader = this.uploader.show_uploader;
        this.$el.html(this.template({ ...modelCopy, useBgAsImage }));
        this.$el.css(bgCss);

        _.forEach(
            this.model.get('copy').ads,
            _.bind(function(value, key) {
                if (value.active.toLowerCase() === 'true') {
                    console.log('ads', key, value.units);

                    _.forEach(
                        value.units,
                        _.bind(function(data, unit) {
                            this.$('.ad-' + key).append(
                                new ViewAd({
                                    model: _.extend(data, {
                                        location: key,
                                        placement: unit
                                    }),
                                    className:
                                        'view-ad _' +
                                        unit +
                                        ' ' +
                                        data.className
                                }).render().$el
                            );
                        }, this)
                    );
                }
            }, this)
        );

        _.forEach(
            this.model.get('filter').models,
            _.bind(function(item) {
                this.$('.wrapper-filters').append(
                    new ViewFilter({
                        model: item
                    }).render().$el
                );
            }, this)
        );

        return this;
    },
    /**
     * [uploader description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    uploader: function(e) {
        e.preventDefault();
        // PubSub.trigger('navigate', 'uploader');
        PubSub.trigger('uploader:open');
    },
    /**
     * [shareToFacebook description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    shareToFacebook: function(e) {
        e.preventDefault();

        var copy = this.model.get('copy').sharing.facebook;
        var picture = copy.picture;
        var link = copy.link;
        var name = copy.name;
        var description = copy.description;
        var params = {
            name: name,
            picture: picture,
            description: description,
            link: link,
            display: 'popup'
        };

        PubSub.trigger('facebookShare', params);
    }
});
