module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<%- namespace %>.view-uploader label { color: <%- label_text_color %>; } <%- namespace %>.view-uploader label.checkbox-inline { color: <%- checkbox_label_text_color %>; } <%- namespace %>.view-uploader .uploader-form-wrapper .error-message { color: <%- error_text_color %>; } <%- namespace %>.view-uploader .uploader-form-wrapper .separator { border-top: <%- separator_border_style %>; border-color: <%- separator_border_color ? (separator_border_color.length > 0 ? separator_border_color : 'transparent') : 'transparent' %>; } <%- namespace %> .separator h3 { color: <%- separator_text_color %>; } <%- namespace %> .uploader-form-wrapper h1 { color: <%- headline1_text_color %>; } <%- namespace %> .uploader-form-wrapper h2 { color: <%- headline2_text_color %>; } "
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ; __append( namespace )
    ; __append(".view-uploader label { color: ")
    ; __append( label_text_color )
    ; __append("; } ")
    ; __append( namespace )
    ; __append(".view-uploader label.checkbox-inline { color: ")
    ; __append( checkbox_label_text_color )
    ; __append("; } ")
    ; __append( namespace )
    ; __append(".view-uploader .uploader-form-wrapper .error-message { color: ")
    ; __append( error_text_color )
    ; __append("; } ")
    ; __append( namespace )
    ; __append(".view-uploader .uploader-form-wrapper .separator { border-top: ")
    ; __append( separator_border_style )
    ; __append("; border-color: ")
    ; __append( separator_border_color ? (separator_border_color.length > 0 ? separator_border_color : 'transparent') : 'transparent' )
    ; __append("; } ")
    ; __append( namespace )
    ; __append(" .separator h3 { color: ")
    ; __append( separator_text_color )
    ; __append("; } ")
    ; __append( namespace )
    ; __append(" .uploader-form-wrapper h1 { color: ")
    ; __append( headline1_text_color )
    ; __append("; } ")
    ; __append( namespace )
    ; __append(" .uploader-form-wrapper h2 { color: ")
    ; __append( headline2_text_color )
    ; __append("; } ")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}