'use strict';

import _ from 'lodash';
import Backbone from 'backbone';

var base = function(options) {
    Backbone.View.call(this, options);
    this.isActiveView = true;
};

_.extend(base.prototype, Backbone.View.prototype, {
    events: function() {
        return {
            destroyed: 'removeHandler'
        };
    },
    /**
     * [removeHandler description]
     * @return {[type]} [description]
     */
    removeHandler: function() {
        console.log(
            'View has been destroyed, do we need to stop any Timer?',
            this.className
        );
        this.isActiveView = false;
        this.stopListening();
    },
    /**
     * [renderedToDom description]
     * @return {[type]} [description]
     */
    renderedToDom: function() {}
});

base.extend = Backbone.View.extend;

export default base;
