'use strict';
import _ from 'lodash';
import TailorModel from 'tscom-tailor';
import PubSub from './../PubSub';

export default TailorModel.extend({
    /**
     * Modified to take in ruleset_collection_id(s)
     * @param  {[type]} attrs   [description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    initialize: function(attrs, options) {
        if (options.ruleset_collection_id) {
            this.ruleset_collection_id = options.ruleset_collection_id;
        }

        if (options.ruleset_collection_ids) {
            this.ruleset_collection_ids = options.ruleset_collection_ids;
        }

        this.listenTo(
            PubSub,
            'userIp',
            function(data) {
                console.log('ipUser set in tailor');
                this.userIP = data['ip-user'];
            }.bind(this)
        );

        TailorModel.prototype.initialize.call(this, attrs, options);
    },
    /**
     * Modified so we can pass in ruleset_collection_id(s) from CMS without
     * affecting custom_parameters
     * @param {[type]} data [description]
     */
    setUpData: function(data) {
        //FFD-168: This will store the URL for the
        //source input file under custom_parameters.source_media
        var originalUpload = {
            source_media: '${upload:1}'
        };

        data = _.extend(data, this.get('videoInfo'), originalUpload);

        //map keys per TechOps request
        var keyMap = {
            state: 'user_state',
            country: 'user_country'
        };

        var newData = {};

        _.each(data, function(value, key) {
            key = keyMap[key] || key;
            newData[key] = value;
        });
        data = _.extend(newData, {
            'ip-user': this.userIp || '--',
            akamai_data: {
                state: this.geoState || '--',
                country: this.geoCountry || '--'
            }
        });

        var flex = {
            id: _.uniqueId(
                new Date().getTime() + '' + Math.floor(Math.random() * 99)
            ),
            topics: this.topics,
            user_name: data.user_name || '--',
            message: data.message || '--',
            country: data.country || this.geoCountry,
            state: data.state || this.geoState,
            custom_parameters: JSON.stringify(data),
            //'video': data.video,

            // 'ruleset_collection_id': this.ruleset_collection_id || data.ruleset_collection_id || "0",//this.rulesetCollectionId,
            ruleset_collection_ids:
                this.ruleset_collection_ids ||
                data.ruleset_collection_ids ||
                '0', //this.rulesetCollectionId,

            timestamp: new Date().getTime()
        };
        //Populate the optional Flex fields if the form model has matching fields.
        for (var i = 0; i < this.optional_fields.length; i++) {
            var name = this.optional_fields[i];
            var value = data[name];
            if (!_.isUndefined(value) && !_.isNull(value)) {
                flex[name] = value;
            }
        }

        this.set({
            flex: flex,
            files: data.file || this.get('files')
        });
    },
    /**
     * Sets a new topic id that will be sent with Flex data
     * @param {[Number]} newTopicId 
     */
    updateTopicId: function(newTopicId) {
        if(newTopicId){
            this.topics = newTopicId;
        }
      
    }
});
