'use strict';
import Backbone from 'backbone';
import Model from './../models/message';

export default Backbone.Collection.extend({
    model: Model,
    initialize: function () {},

    comparator: function (a, b) {
        var direction = 1; // Asc
        //var direction = -1; // Desc

        a = a.get('position');
        b = b.get('position');
        return direction * a.localeCompare(b);
    }
});
