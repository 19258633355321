module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<% if(embed.length>0) { %> <div class=\"tile-wrapper invisible\"> <%- link.url.length>0? '<a href=\" '+link.url+' \" target=\" '+link.target+' \" data-track=\"' +link.track+ '\">' : '' %> <figure class=\"image-content\"> <%- embed %> </figure> <%- link.url.length>0? '</a>' : '' %> </div> <% } else if (image.length>0) { %> <div class=\"tile-wrapper invisible\"> <%- link.url.length>0? '<a href=\" '+link.url+' \" target=\" '+link.target+' \" data-track=\"' +link.track+ '\">' : '' %> <figure class=\"image-content\"> <img src=\"<%- image %>\"> </figure> <%- link.url.length>0? '</a>' : '' %> </div> <% } %> "
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  if(embed.length>0) { 
    ; __append(" <div class=\"tile-wrapper invisible\"> ")
    ; __append( link.url.length>0? '<a href=" '+link.url+' " target=" '+link.target+' " data-track="' +link.track+ '">' : '' )
    ; __append(" <figure class=\"image-content\"> ")
    ; __append( embed )
    ; __append(" </figure> ")
    ; __append( link.url.length>0? '</a>' : '' )
    ; __append(" </div> ")
    ;  } else if (image.length>0) { 
    ; __append(" <div class=\"tile-wrapper invisible\"> ")
    ; __append( link.url.length>0? '<a href=" '+link.url+' " target=" '+link.target+' " data-track="' +link.track+ '">' : '' )
    ; __append(" <figure class=\"image-content\"> <img src=\"")
    ; __append( image )
    ; __append("\"> </figure> ")
    ; __append( link.url.length>0? '</a>' : '' )
    ; __append(" </div> ")
    ;  } 
    ; __append(" ")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}