'use strict';
import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';

import template from './../templates/media-detail.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';
import CSSView from './css';
import videojs from 'video.js';
var styleId;
import TileDetailsView from './tile-details';
import cssTemplate from './../templates/media-detail-css.ejs';

import { parseHashtagsInString } from './../util/helpers';


export default BaseView.extend({
    template: template,
    tagName: 'div',
    className: 'view-media-detail',
    /**
     * [events description]
     * @return {[type]} [description]
     */
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .close': 'close',
            'click .controls-flex .btn-facebook': 'shareToFacebook',
            'click .icon-arrow-left': 'prevTile',
            'click .icon-arrow-right': 'nextTile'
        });
    },
    /**
     * [initialize description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    initialize: function(options) {
        _.bindAll(this, 'render');
        styleId = this.className + _.uniqueId();
        this.dataModel = options.dataModel;
        this.custom = options.custom;
        this.flexShare = options.flexShare;

        this.$el.addClass(styleId);
        this.listenTo(this.model, 'change:copy', this.render);
        this.video_uid =
            'videojs_' +
            Math.floor(Date.now() / 1000) +
            _.uniqueId();

        this.listenTo(PubSub, 'keypress:down', this.keyAction.bind(this));
    },
    /**
     * [render description]
     * @return {[type]} [description]
     */
    render: function() {
        if (this.CSSView) {
            this.CSSView.remove();
        }

        this.CSSView = new CSSView({
            model: _.extend(
                {
                    headline_text_color: '',
                    paragraph_text_color: ''
                },
                this.model.get('copy').styles,
                this.model.get('copy').customizations || {}
            ),
            namespace:
                '#' + this.model.get('uid') + ' .' + styleId.replace(' ', '.'),
            template: cssTemplate
        });

        Backbone.$('head').append(this.CSSView.render().$el);

        console.log(
            'media-detail view',
            _.result(this, 'events'),
            this.$el,
            this.model
        );

        this.model.toJSON().data.custom = this.custom; // display the ig actions or not, this may be temporary
        var model = this.model.toJSON();

        model.video_uid = this.video_uid;
        model.tweetShare = this.flexShare.twitter;

        var tempData = model.data;
        var regexPatt = /(^|\s)(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/gi;
        var matchedLinks = tempData.message.match(regexPatt);

        var finalMessage = model.data.message;
        if (matchedLinks != null) {
            var tempMessage = tempData.message;
            _.each(
                matchedLinks,
                _.bind(function(link) {
                    var replaceText = '';
                    if (link.charAt(0) === ' ') {
                        replaceText = link.substring(1, link.length);
                    } else {
                        replaceText = link;
                    }

                    var matchedURL =
                        '<a href="' +
                        replaceText +
                        '" target="_blank" class="hash-link">' +
                        replaceText +
                        '</a>';
                    var replacedMessage = tempMessage.replace(
                        replaceText,
                        matchedURL
                    );

                    if (/(twitter)/i.test(tempData.source)) {
                        tempMessage = parseHashtagsInString({
                            str: replacedMessage,
                            classname: 'hash-link',
                            url: 'https://twitter.com/hashtag/'
                        });
                    } else if (/(instagram)/i.test(tempData.source)) {
                        tempMessage = parseHashtagsInString({
                            str: replacedMessage,
                            classname: 'gram-hash-link',
                            url: 'https://www.instagram.com/explore/tags/'
                        });
                    } else {
                        tempMessage = replacedMessage;
                    }
                }, this)
            );

            finalMessage = tempMessage;
        } else {
            if (/(twitter)/i.test(tempData.source)) {
                finalMessage = parseHashtagsInString({
                    str: tempData.message,
                    classname: 'hash-link',
                    url: 'https://twitter.com/hashtag/'
                });
            } else if (/(instagram)/i.test(tempData.source)) {
                finalMessage = parseHashtagsInString({
                    str: tempData.message,
                    classname: 'gram-hash-link',
                    url: 'https://www.instagram.com/explore/tags/'
                });
            } else {
                // do nothing
            }
        }

        var customParameters = JSON.parse(model.data.flex_custom_parameters);
        model.data.message = finalMessage || customParameters.about;

        this.$el.html(this.template(model));

        var details = (this.details = new TileDetailsView({
            model: {
                type: this.model.toJSON().type,
                data: this.model.toJSON().data,
                video_uid: this.video_uid
            }
        }).render().$el);

        this.$('.details-wrapper').append(details);

        $(document).on(
            'webkitfullscreenchange mozfullscreenchange fullscreenchange',
            function() {
                var documentState =
                    document.fullScreen ||
                    document.mozFullScreen ||
                    document.webkitIsFullScreen;
                var state = documentState ? 'FullscreenOn' : 'FullscreenOff';

                PubSub.trigger('fullscreen-event', state);
            }
        );

        return this;
    },
    /**
     *
     */
    renderedToDom: function() {
       const modelType = this.model.toJSON().type;

       if (modelType == 'video' || modelType == 'celebrity video') {
           videojs(this.video_uid);
       }
    },
    /**
     * Navigate to previous tile
     */
    prevTile: function() {
        PubSub.trigger('media:prev', this.dataModel);
    },
    /**
     * Navigate to next tile
     */
    nextTile: function() {
        PubSub.trigger('media:next', this.dataModel);
    },
    /**
     * Key action event handler
     */
    keyAction: _.debounce(function(e) {
        if (e.keyCode === 37) {
            // left arrow keypress
            PubSub.trigger('media:prev', this.dataModel);
        } else if (e.keyCode === 39) {
            // right arrow keypress
            PubSub.trigger('media:next', this.dataModel);
        }
    }, 250),
    /**
     * [shareToFacebook description]
     * @param  {[type]} e [description]
     */
    shareToFacebook: function(e) {
        e.preventDefault();

        var params = {
            name: this.flexShare.facebook.name,
            picture: this.flexShare.facebook.picture,
            description: this.flexShare.facebook.description,
            link: this.flexShare.facebook.link,
            display: 'popup'
        };

        PubSub.trigger('facebookShare', params);
    },
    /**
     * [close description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    close: function(e) {
        console.log('close');
        if (e) {
            e.preventDefault();
        }

        PubSub.trigger('modals:close');
        PubSub.trigger('timer:reset');
    },
    /**
     * Remove the media tile view
     */
    remove: function() {
        this.CSSView.remove();
        this.CSSView = null;
        this.style_id = null;
        this.model = null;
        this.off();
        Backbone.View.prototype.remove.call(this);
    }
});
