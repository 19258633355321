import _ from 'lodash';
import base64 from 'crypto-js/enc-base64';
import sha1 from 'crypto-js/hmac-sha1';
import sha256 from 'crypto-js/hmac-sha256';

/**
 *
 * @param string
 * @returns {string}
 */
export const capitalize = string => {
    return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase();
};
/**
 * Get query string param by name
 * window.location.search()
 *
 * @param name - String of param name
 * @return {string} value or empty string if not found
 */
export const getQueryParamByName = name => {
    var regexS = '[\\?&]' + name + '=([^&#]*)';
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.search);
    if (results === null) {
        return '';
    } else {
        return decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
};
/**
 * https://github.com/kvz/phpjs/blob/master/functions/url/urlencode.js
 * @param url
 * @return {string} encoded url
 */
export const urlencode = str => {
    str = (str + '').toString();
    // Tilde should be allowed unescaped in future versions of PHP (as reflected below), but if you want to reflect current
    // PHP behavior, you would need to add '.replace(/~/g, '%7E');' to the following.
    return encodeURIComponent(str)
        .replace(/!/g, '%21')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/\*/g, '%2A')
        .replace(/%20/g, '+');
};

export const parseHashtagsInString = data => {
    var oldStr = data.str;
    var pattern = /(^|\s)#([a-z\d-]+)/gi;
    var replaceWithLinks = function(match, p1, p2) {
        // console.log('replaceWithLinks', "match:", match, "p1:", p1, "p2:", p2);
        return (
            p1 +
            '<a target="_blank" href="' +
            data.url +
            p2 +
            '" class="' +
            data.classname +
            '">#' +
            p2 +
            '</a>'
        );
    };
    var newStr = oldStr.replace(pattern, replaceWithLinks);
    return newStr;
};
/**
 * Returns a copy of the object with these values removed: null, "", undefined
 *
 * @param o - object literal
 * @return {object} clone of o, with falsy values removed
 */
export const compactObject = o => {
    var clone = _.clone(o);
    _.each(clone, function(v, k) {
        if (
            _.isNull(v) ||
            _.isUndefined(v) ||
            (_.isString(v) && v.length === 0)
        ) {
            delete clone[k];
        }
    });
    return clone;
};

/**
   * var collections = [
      {id: 1, name: 'xyz'},
      {id: 2,  name: 'ds'},
      {id: 3,  name: 'rtrt'},
      {id: 4,  name: 'nhf'},
      {id: 5,  name: 'qwe'}
    ];
   * ex: _.findByValues([collections, "id", [1,2,3,4]])
   *
   */
export const findByValues = (collection, property, values) => {
    return _.filter(collection, function(item) {
        return _.contains(values, item[property]);
    });
};

/*
 * checks if true
 */
export const isTrue = function(value) {
    return ['true', '1', true, 1, 'yes'].indexOf(value) >= 0;
};

export const emailHasher = ( email, versionId, versionCheck ) => {
    if (!email) {
        return;
    }

    const secret = versionId + versionCheck;
    const timestamp = Date.now();
    const sha256Hash = sha256( `${email}${timestamp}`, secret);
    const stringifiedHash = base64.stringify(sha256Hash);
    const sha1Hash = sha1(stringifiedHash, secret);
    const stringifiedHash256 = base64.stringify(sha1Hash);

    return fixedEncodeUriComponent(stringifiedHash256);
};

export const fixedEncodeUriComponent = ( str ) => {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}