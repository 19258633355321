'use strict';
import _ from 'lodash';
import Backbone from 'backbone';
import Model from './../models/filter';

export default Backbone.Collection.extend({
    model: Model,
    /**
     * [comparator description]
     * @param  {[type]} model [description]
     * @return {[type]}       [description]
     */
    comparator: function(model) {
        return model.get('id');
    },
    /**
     *
     */
    initialize: function(attrs, options) {
        this.wid = options.wid;
    },
    /**
     * Override _prepareModel function to pass in WID
     */
    _prepareModel: function(attrs, options) {
        return new Model(
            attrs,
            _.extend(options, {
                wid: this.wid
            })
        );
    }
});
