'use strict';
import _ from 'lodash';
import Backbone from 'backbone';

var base = function(attrs, options) {
    options = options || {};
    this.cms = options.cms;
    this.textKey = options.textKey;

    Backbone.Model.apply(this, arguments);
};
_.extend(base.prototype, Backbone.Model.prototype, {
    defaults: function() {
        return {
            copy: {},
            uid: ''
        };
    },
    initialize: function() {
        console.log('initialize base model', this.textKey, this.toJSON());
        if (this.cms) {
            this.listenTo(
                this.cms,
                'change:text',
                _.bind(function(model, value) {
                    this.updateCopy(model, value);
                }, this)
            );
        }
    },
    /**
     *
     */
    fetch: function() {
        console.warn('The Base model does not support "fetch"');
    },
    /**
     *
     */
    save: function() {
        console.warn('The Base model does not support "save"');
    },
    /**
     *
     */
    destroy: function(options) {
        options = options ? _.clone(options) : {};
        var model = this;
        var wait = options.wait;

        var destroy = function() {
            model.stopListening();
            model.trigger('destroy', model, model.collection, options);
        };

        if (!wait) destroy();

        console.warn('The Base model does not support "destroy"');
    },
    /**
     * [updateCopy description]
     * @param  {[type]} model [description]
     * @param  {[type]} value [description]
     * @return {[type]}       [description]
     */
    updateCopy: function(model, value) {
        if (!this.textKey) {
            return;
        }

        // var currentCopy = this.toJSON()['copy'];
        // var newCopy = value[this.textKey];

        // console.log('UPDATE COPY', this.textKey, _.isEqual(currentCopy, newCopy), currentCopy, newCopy);

        this.set({
            copy: _.clone(value[this.textKey], true)
        });
    }
});
base.extend = Backbone.Model.extend;

export default base;
