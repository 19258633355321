module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<% if(typeof type !== 'undefined' && type.toLowerCase()===\"embed\") { %> <%- embed %> <% } else if (typeof sponsored_text !== 'undefined' && image.length>0) { %> <div class=\"sponsored\"> <%- link.url.length>0? '<a href=\" '+link.url+' \" target=\" '+link.target+' \" data-track=\"' +link.track+ '\">' : '' %> <figcaption class=\"hidden-xs\"><small><%- sponsored_text %></small></figcaption> <figure> <img src=\"<%- image %>\"> </figure> <%- link.url.length>0? '</a>' : '' %> <div class=\"bd visible-xs-block\"><small><%- sponsored_text_mobile %></small></div> </div> <% } else if (image.length>0) { %> <%- link.url.length>0? '<a href=\" '+link.url+' \" target=\" '+link.target+' \" data-track=\"' +link.track+ '\">' : '' %> <figure> <img src=\"<%- image %>\"> </figure> <%- link.url.length>0? '</a>' : '' %> <% } %> "
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  if(typeof type !== 'undefined' && type.toLowerCase()==="embed") { 
    ; __append(" ")
    ; __append( embed )
    ; __append(" ")
    ;  } else if (typeof sponsored_text !== 'undefined' && image.length>0) { 
    ; __append(" <div class=\"sponsored\"> ")
    ; __append( link.url.length>0? '<a href=" '+link.url+' " target=" '+link.target+' " data-track="' +link.track+ '">' : '' )
    ; __append(" <figcaption class=\"hidden-xs\"><small>")
    ; __append( sponsored_text )
    ; __append("</small></figcaption> <figure> <img src=\"")
    ; __append( image )
    ; __append("\"> </figure> ")
    ; __append( link.url.length>0? '</a>' : '' )
    ; __append(" <div class=\"bd visible-xs-block\"><small>")
    ; __append( sponsored_text_mobile )
    ; __append("</small></div> </div> ")
    ;  } else if (image.length>0) { 
    ; __append(" ")
    ; __append( link.url.length>0? '<a href=" '+link.url+' " target=" '+link.target+' " data-track="' +link.track+ '">' : '' )
    ; __append(" <figure> <img src=\"")
    ; __append( image )
    ; __append("\"> </figure> ")
    ; __append( link.url.length>0? '</a>' : '' )
    ; __append(" ")
    ;  } 
    ; __append(" ")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}