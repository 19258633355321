'use strict';
import _ from 'lodash';
import $ from 'jquery';

import template from './../templates/tile.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';
import TileDetailsView from './tile-details';

import { parseHashtagsInString } from './../util/helpers';

export default BaseView.extend({
    template: template,
    tagName: 'li',
    className: 'view-tile',
    /**
     * [events description]
     * @return {[type]} [description]
     */
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            click: 'clickTile',
            'click .controls-flex .btn-facebook': 'shareToFacebook'
        });
    },
    /**
     * [initialize description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    initialize: function() {
        _.bindAll(this, 'render');
        this.listenTo(
            PubSub,
            'AnimationFrame',
            _.bind(this.setItemHeight, this)
        );
    },
    /**
     * [render description]
     * @return {[type]} [description]
     */
    render: function() {
        //console.log('tile view', _.result(this, 'events'), this.$el);

        var tempData = this.model.data;
        var regexPatt = /(^|\s)(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/gi;
        var matchedLinks = tempData.message.match(regexPatt);

        var finalMessage = '';
        if (matchedLinks != null) {
            var tempMessage = tempData.message;
            _.each(
                matchedLinks,
                _.bind(function(link) {
                    var replaceText = '';
                    if (link.charAt(0) === ' ') {
                        replaceText = link.substring(1, link.length);
                    } else {
                        replaceText = link;
                    }

                    var matchedURL =
                        '<a href="' +
                        replaceText +
                        '" target="_blank" class="hash-link">' +
                        replaceText +
                        '</a>';
                    var replacedMessage = tempMessage.replace(
                        replaceText,
                        matchedURL
                    );

                    if (/(twitter)/i.test(tempData.source)) {
                        tempMessage = parseHashtagsInString({
                            str: replacedMessage,
                            classname: 'hash-link',
                            url: 'https://twitter.com/hashtag/'
                        });
                    } else if (/(instagram)/i.test(tempData.source)) {
                        tempMessage = parseHashtagsInString({
                            str: replacedMessage,
                            classname: 'gram-hash-link',
                            url: 'https://www.instagram.com/explore/tags/'
                        });
                    } else {
                        tempMessage = replacedMessage;
                    }
                }, this)
            );

            finalMessage = tempMessage;
        } else {
            finalMessage = /(twitter)/i.test(tempData.source)
                ? parseHashtagsInString({
                      str: tempData.message,
                      classname: 'hash-link',
                      url: 'https://twitter.com/hashtag/'
                  })
                : _.trunc(tempData.message, { length: 140, separator: ' ' });
        }

        this.model.data.message = finalMessage;

        this.$el.html(this.template(this.model));

        if (/(image|video)/i.test(this.model.type)) {
            this.fetchImage();
        }

        var details = (this.details = new TileDetailsView({
            model: {
                type: this.model.type,
                data: this.model.data
            }
        }).render().$el);
        this.$('.details-wrapper').append(details);

        return this;
    },
    /**
     * [fetchImage description]
     * @return {[type]} [description]
     */
    fetchImage: function() {

      var isVideo = this.model.type === 'video';
      var containsImage = this.model.data.contains.indexOf('contains_image') > -1;
      var videoPlaceholder = this.model.copy.customizations.video_tile_thumbnail_placeholder;
      var imageSrc = this.model.data.images;

      if (isVideo && !containsImage) {
        if (!videoPlaceholder) return;
        imageSrc = videoPlaceholder;
      }

      var image = $('<img alt="" />');
      image.bind('load', this.imageLoaded.bind(this, image));
      image.bind('error', function() {
        PubSub.trigger('tile:remove', this.model.data.id);
        this.$el.remove();
      }.bind(this));
      image.attr('src', imageSrc);
    },
    /**
     * [relayVoteItemHeight description]
     * @return {[type]} [description]
     */
    setItemHeight: function() {
        if (!this.isActiveView) {
            return;
        }
        var rect = this.$el[0].getBoundingClientRect();
        if (
            typeof this.myHeight === 'undefined' ||
            this.myHeight !== Math.floor(rect.width)
        ) {
            this.myHeight = Math.floor(rect.width);
            // console.log('setItemHeight', this.myHeight);
            PubSub.trigger('relayVoteItemHeight', this.myHeight);
        }
    },
    /**
     * [imageLoaded description]
     * @param  {[type]} image [description]
     * @return {[type]}       [description]
     */
    imageLoaded: function(image) {
        if (!this.isActiveView) {
            return;
        }
        console.log('imageLoaded', image);
        this.$el.removeClass('hidden');
        this.$('figure.image-content').append(image);
    },
    /**
     * [clickTile description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    clickTile: function(e) {
        if (/(image|video|text)/i.test(this.model.type)) {
            e.preventDefault();
            this.prepGoogleEventTracking();
            PubSub.trigger('media:open', this.model.data.id);
        }
    },
    /**
     * [shareToFacebook description]
     * @param  {[type]} e [description]
     */
    shareToFacebook: function(e) {
        e.preventDefault();

        var copy = this.model.copy.sharing_flex.facebook;
        var picture = copy.picture;
        var link = copy.link;
        var name = copy.name;
        var description = copy.description;
        var params = {
            name: name,
            picture: picture,
            description: description,
            link: link,
            display: 'popup'
        };

        PubSub.trigger('facebookShare', params);
    },

    /**
     * Prep google event to fire
     */
    prepGoogleEventTracking: function() {
        var googleEventObj = {
            category: 'button',
            action: 'click',
            label: 'tile-click',
            name: 'tile-click-event'
        };

        PubSub.trigger('track-event', googleEventObj);
    }
});
