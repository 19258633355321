'use strict';
import _ from 'lodash';
import template from './../templates/filter.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';

export default BaseView.extend({
    template: template,
    tagName: 'div',
    className: 'view-filter',
    /**
     * [events description]
     * @return {[type]} [description]
     */
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .topic-filter': 'onClickTopicFilter',
            'click .type-filter': 'onClickTypeFilter'
        });
    },
    /**
     * [initialize description]
     * @return {[type]}         [description]
     */
    initialize: function() {
        _.bindAll(this, 'render');


        this.listenTo(this.model, 'change', this.render);
        this.noRefresh = true;
        this.initLoad = true;
    },
    /**
     * [render description]
     * @return {[type]} [description]
     */
    render: function() {
        var item = this.model.toJSON();
        this.$el.html(
            this.template({
                item: item
            })
        );
        return this;
    },
    /**
     * [onClickTopicFilter description]
     * @return {[type]} [description]
     */
    onClickTopicFilter: function(e) {
        e.preventDefault();
        console.log('onClickTopicFilter', this.model, e);

        var isActive = this.$('.topic-filter').hasClass('active');
        this.$('.topic-filter').toggleClass('active', !isActive);
        this.model.save(
            { user_state: isActive ? 'off' : 'on' },
            { ajaxSync: false }
        );

        // if(this.initLoad && (this.model.get('state') === "off") && !isActive) {
        // if(!isActive) {
        //   this.noRefresh = false;
        //   this.initLoad = false;
        // } else {
        //   this.noRefresh = true;
        // }

        this.noRefresh = false;
        var evt = {
            filters: this.model.collection,
            noRefresh: this.noRefresh
        };
        PubSub.trigger('filtersChanged', evt);
        PubSub.trigger('mosaic:fadeout', null);

        // this.render();
        // PubSub.trigger('filtersChanged', this.model.collection);

        // var filterID = this.model.get('id');
        // if(isActive) {
        //   // hide tiles with this filter
        //   PubSub.trigger('mosaic:update:topic', { filterID: filterID, action: 'hide' });
        // } else {
        //   // show tiles with this filter
        //   PubSub.trigger('mosaic:update:topic', { filterID: filterID, action: 'show' });
        // }
    },
    /**
     * [onClickTypeFilter description]
     * @return {[type]} [description]
     */
    onClickTypeFilter: function(e) {
        e.preventDefault();
        console.log('onClickTypeFilter', this.model, e);

        var isActive = this.$('.type-filter').hasClass('active');
        this.$('.type-filter').toggleClass('active', !isActive);
        this.model.save(
            { user_state: isActive ? 'off' : 'on' },
            { ajaxSync: false }
        );

        // if(this.initLoad && (this.model.get('state') === "off") && !isActive) {
        // if(!isActive) {
        //   this.noRefresh = false;
        //   // this.initLoad = false;
        // } else {
        //   this.noRefresh = true;
        // }

        this.noRefresh = false;
        var evt = {
            filters: this.model.collection,
            noRefresh: this.noRefresh
        };
        PubSub.trigger('filtersChanged', evt);
        PubSub.trigger('mosaic:fadeout', null);

        // this.render();
        // PubSub.trigger('filtersChanged', this.model.collection);

        // var filterType = this.model.get('type');
        // if(isActive) {
        //   // hide tiles with this filter
        //   PubSub.trigger('mosaic:update:type', { filterType: filterType, action: 'hide' });
        // } else {
        //   // show tiles with this filter
        //   PubSub.trigger('mosaic:update:type', { filterType: filterType, action: 'show' });
        // }
    }
});
