'use strict';
import _ from 'lodash';
import Backbone from 'backbone';
import PubSub from './../PubSub';

export default Backbone.Model.extend({
    defaults: {
        scriptLoaded: false,
        data: {},
        video: ''
    },
    url: '//vine.co/oembed.json',
    /**
     * [initialize description]
     * @param  {[type]} attrs   [description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    initialize: function() {
        _.bindAll(this, 'loadScript', 'getVineData');

        this.loadScript(document, 'script', 'vine-embed');

        PubSub.on('getVineData', _.bind(this.getVineData, this));
    },
    /**
     * [loadScript description]
     * @param  {[type]} d  [description]
     * @param  {[type]} s  [description]
     * @param  {[type]} id [description]
     * @return {[type]}    [description]
     */
    loadScript: function(d, s, id) {
        var js;
        var fjs = d.getElementsByTagName(s)[0];

        if (d.getElementById(id)) {
            this.set('scriptLoaded', true);
            return;
        }

        js = d.createElement(s);
        js.id = id;
        js.src =
            document.location.protocol +
            '//platform.vine.co/static/scripts/embed.js';
        fjs.parentNode.insertBefore(js, fjs);
    },
    /**
     * [getVineData description]
     * @param  {[type]} video [description]
     * @return {[type]}       [description]
     */
    getVineData: function() {
        // if(!this.get(scriptLoaded)) {
        // 	this.loadScript();
        // 	return;
        // }
        // var url = this.url + '?' + video;
        // url = this.fixedEncodeURIComponent(url);
        // debugger;
    },
    /**
     * [fixedEncodeURIComponent description]
     * @param  {String} str [description]
     * @return {[type]}     [description]
     */
    fixedEncodeURIComponent: function(str) {
        return encodeURIComponent(str)
            .replace(/[!'()]/g, escape)
            .replace(/\*/g, '%2A');
    }
});
