'use strict';
import _ from 'lodash';

import template from './../templates/page.ejs';
import BaseView from './../views/base';
import ViewHeader from './../views/header';

export default BaseView.extend({
    template: template,
    className: 'view-page container',
    /**
     * [initialize description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    initialize: function(options) {
        _.bindAll(this, 'render');

        this.model = options.model;
        this.uploader = options.uploader;

        this.landscapeView = options.displayLandscape;
    },
    /**
     * [render description]
     * @return {[type]} [description]
     */
    render: function() {
        this.$el.html(this.template(this.model.get('copy')));

        if (this.landscapeView) {
            this.$el.addClass('landscape');
        }
        //test if iframed
        //test for touch events support and if not supported, attach .no-touch class
        this.$el.addClass(
            (top === self ? 'frame-false' : 'frame-true') +
                (!('ontouchstart' in document.documentElement)
                    ? ' no-touch'
                    : '')
        );
        this.$('header').append(
            new ViewHeader({
                model: this.model.get('header'),
                uploader: this.uploader
            }).render().$el
        );

        return this;
    }
});
