import $ from 'jquery';
import _ from 'lodash';
import Backbone from 'backbone';
import Moderation from 'tscom-moderation';
import { compactObject } from './../util/helpers';

Backbone.$ = $;

export default Moderation.extend({
    apiUrl: 'https://voteapi.votenow.tv/moderation/getMessages',

    tempItems: {},

    defaults: {
        messages: [],
        sources: '',
        urlParams: {
            contains: '',
            noRetweet: true
        },
        itemCount: 1,
        noProfanity: true,
        items: null,
        pollTimer: null,
        allowPolling: true,
        nextPosition: '',
        updateFrequency: 10,
        success: false,
        itemsLength: 0,
        initialized: false
    },

    options: {
        topic_id: null,
        offset: null, // Limit for response length -- positive for older messages, negative for newer messages; Can be used in correlation with position for pagination purposes (you would set a position and a positive or negative offset to go back and forth)
        position: null,
        contains: '', // Comma delimited.  Uses OR operator.
        notContains: '', // Comma delimited.  Users OR operator.
        isVerified: 'false', // Set to true to only return Twitter verified users
        userScore: '', // Comma delimited userScore range.
        length: '', // Comma delimited message length range.

        keyword: '', // Alpha-numeric string of keywords. Comma delimited.
        include: 'false', // true: include keywords, false: exclude keywords.

        country: null, // Filter by country code.  Only one allowed.

        state: '', // Filter by state.  Only one allowed.

        includeComments: 'false', // include comments object

        // not sure what the remainder of these do...
        display: '',
        format: '',
        displayName: '',
        relevance: '',
        group: '',
        group_id: '',
        status: '',
        showExpired: ''
    },

    initialize: function(attrs, options) {
        _.bindAll(this, 'fetchSuccess');

        this.set({
            urlParams: {
                topic_id: attrs.topic_id,
                contains: options.contains,
                noRetweet: true,
                moderated: attrs.moderated
            },
            nextPosition: attrs.nextPosition
        });

        options = options || {};
        // this.options = _.extend(_.clone(this.options, true), options);
        this.options = options;
        this.options.offset = parseInt(options.offset, 10);
        this.positionDirection = attrs.positionDirection;

        // Backbone.Model.apply(this, arguments);
        // this.apiUrl = window.location.protocol + this.apiUrl;
    },

    fetch: function(options) {
        var nextPosition = this.get('nextPosition');
        options = _.extend({ data: this.options }, options);
        options.data = compactObject(options.data);

        if (nextPosition) {
            options.data.position = nextPosition;
        }

        options.data.offset = parseInt(options.data.offset, 10);

        console.log('fetch', options);

        return Backbone.Model.prototype.fetch
            .call(this, options)
            .done(this.fetchSuccess);
    },

    fetchSuccess: function(response) {
        if (!_.isEmpty(response)) {
            if (this.positionDirection === 'old') {
                console.log(
                    'FETCH OLD:',
                    parseInt(response[_.size(response) - 1].position, 10) + 1
                );
                this.set({
                    nextPosition:
                        parseInt(response[_.size(response) - 1].position, 10) +
                        1
                });
            }
        }
    },
    parse: function(response) {
        var ret = [];

        _.each(
            response,
            function(message, key) {
                ret[key] = this.updateWithCloudfrontAndProtocol(message);
            }.bind(this)
        );
        return {
            messages: ret
        };
    },
    /**
     * tries to update assets url with cloudfront url as well as update the protocol to https
     * note: some messages will not have flex_custom_parameters attribute. if so, just update
     * protocol to https
     */
    updateWithCloudfrontAndProtocol: function(message) {
        try {
            var flexCustomParameters = message.flex_custom_parameters;
            var video = message.video;
            var images = message.images;
            var avatar = message.avatar;

            if (typeof flexCustomParameters === 'undefined') {
                video = video.replace(/^http:\/\//i, 'https://');
                images = images.replace(/^http:\/\//i, 'https://');
                avatar = avatar.replace(/^http:\/\//i, 'https://');

                message.video = video;
                message.images = images;
                message.avatar = avatar;

                return message;
            }

            var customParameters = JSON.parse(flexCustomParameters);
            if (customParameters.cloudfront_url) {
                var cf = customParameters.cloudfront_url;

                var re = new RegExp(
                    /(http|https)\:\/\/s3([a-z0-9\-]+?).amazonaws\.com\/(.+?)\//gi
                );
                video = video.replace(re, cf);
                images = images.replace(re, cf);

                message.images = images;
                message.video = video;
            }
        } catch (e) {
            //Do nothing
        }
        return message;
    }
});
