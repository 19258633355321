'use strict';
import _ from 'lodash';
import Backbone from 'backbone';

export default Backbone.Model.extend({
    url: '',
    /**
     * [defaults description]
     * @return {[type]} [description]
     */
    defaults: function() {
        return {
            to: '',
            position: '',
            body: '',
            user_score: '',
            system_day: '',
            message_length: '',
            user_location: '',
            state: '',
            user_verified: '',
            contains: '',
            from: '',
            user_lang: '',
            broadcast_safe: '',
            json_raw: '',
            timestamp: '',
            id: '',
            flex_custom_parameters: '',
            following: '',
            followers: '',
            user_timezone: '',
            relevance: '',
            status_lang: '',
            profile_description: '',
            status: '',
            total_tweets: '',
            display_message: '',
            hostname: '',
            filter_passed: '',
            avatar: '',
            campaign_id: '',
            country: '',
            profanity: '',
            system_timestamp: '',
            message: '',
            user_name: '',
            system_date: '',
            topics: '',
            system_time: '',
            source: '',
            retweet: '',
            identifier_type: '',
            images: '',
            screen_name: '',
            account_age: '',
            video: '',
            isRemoved: false
        };
    },

    initialize: function() {
        this.updateWithCloudfront();

        this.listenTo(
            this,
            'change:flex_custom_parameters',
            this.updateWithCloudfront.bind(this)
        );
    },

    updateWithCloudfront: function() {
        try {
            var flexCustomParameters = this.get('flex_custom_parameters') || '';
            var customParameters = JSON.parse(flexCustomParameters);

            if (customParameters.cloudfront_url) {
                var cf = customParameters.cloudfront_url;

                var video = this.get('video');
                var images = this.get('images');
                var re = new RegExp(
                    /(http|https)\:\/\/s3([a-z0-9\-]+?).amazonaws\.com\/(.+?)\//gi
                );

                video = video.replace(re, cf);
                images = images.replace(re, cf);

                this.set({
                    video: video,
                    images: images
                });
            }
        } catch (e) {
            //Do nothing
        }
    },

    /**
     *
     */
    validate: function() {},
    /**
     * Override sync method to make requests to the Vote API.
     * @param {string} method - Will be one of: 'create', 'read', 'update', 'destroy'.  We use the value to determine action type.
     * @param {Backbone.Model} model - A reference to this model.
     * @param {object} options - A JS object with options for $.ajax.
     */
    sync: function(method, model, options) {
        var params = {
            url: this.url,
            dataType: 'json',
            crossDomain: true
        };

        _.extend(params, options);

        this.xhr = Backbone.sync(method, model, params)
            .success(_.bind(this.syncSuccess, this))
            .fail(_.bind(this.syncError, this));

        return this.xhr;
    },
    /**
     *
     */
    fetch: function() {
        // this.sync('read', this, options);
        console.warn('The category model does not support "fetch"');
    },
    /**
     *
     */
    save: function() {
        // this.sync('create', this, options);
        console.warn('The category model does not support "save"');
    },
    /**
     *
     */
    destroy: function() {
        console.warn('The category model does not support "destroy"');
    },
    /**
     * Callback for If AJAX send vote success
     */
    syncSuccess: function(response) {
        console.log('syncSuccess', response);
    },
    /**
     * Callback for AJAX send vote fail
     */
    syncError: function(response) {
        console.log('syncError', response);
    }
});
