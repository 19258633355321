module.exports = function anonymous(locals, escapeFn, include, rethrow
) {
rethrow = rethrow || function rethrow(err, str, flnm, lineno, esc){
  var lines = str.split('\n');
  var start = Math.max(lineno - 3, 0);
  var end = Math.min(lines.length, lineno + 3);
  var filename = esc(flnm); // eslint-disable-line
  // Error context
  var context = lines.slice(start, end).map(function (line, i){
    var curr = i + start + 1;
    return (curr == lineno ? ' >> ' : '    ')
      + curr
      + '| '
      + line;
  }).join('\n');

  // Alter exception message
  err.path = filename;
  err.message = (filename || 'ejs') + ':'
    + lineno + '\n'
    + context + '\n\n'
    + err.message;

  throw err;
};
escapeFn = escapeFn || function (markup) {
  return markup == undefined
    ? ''
    : String(markup)
      .replace(_MATCH_HTML, encode_char);
};
var _ENCODE_HTML_RULES = {
      "&": "&amp;"
    , "<": "&lt;"
    , ">": "&gt;"
    , '"': "&#34;"
    , "'": "&#39;"
    }
  , _MATCH_HTML = /[&<>'"]/g;
function encode_char(c) {
  return _ENCODE_HTML_RULES[c] || c;
};
;
var __line = 1
  , __lines = "<% if( item.active === \"1\" ){ %> <div class=\"filter-btn<%- (/(topic)/i.test(item.type)? ' topic-filter' : ' type-filter' ) %><%- ( item.selected ? ' active' : '' ) %>\" data-track=\"button : <%- (/(topic)/i.test(item.type) ? 'topic-filter' : 'type-filter') %>\"> <% if( /(image)/i.test(item.type) ){ %> <div class=\"icon-photo\"> <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"-293 204 205 205\"><path d=\"M-293 361.9l46.4-56.5 43.3 33.1 57.6-82.8L-88 361.9\"/><circle cx=\"-244.8\" cy=\"266.4\" r=\"19.2\"/></svg> </div> <% } %> <% if( /(text)/i.test(item.type) ){ %> <div class=\"icon-text\"> <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"-293 204 205 205\"><path d=\"M-174 349.3h-67.8l-8.1 18.7c-2.6 6.2-4 11.4-4 15.4 0 5.4 2.2 9.4 6.5 11.9 2.5 1.5 8.8 2.7 18.8 3.4v5.2h-63.9v-5.2c6.9-1 12.6-3.9 17-8.6 4.4-4.7 9.9-14.3 16.4-29l68.7-153.2h2.7l69.3 157.5c6.6 14.9 12 24.3 16.3 28.2 3.2 2.9 7.7 4.6 13.6 5.1v5.2h-92.9v-5.2h3.8c7.5 0 12.7-1 15.7-3.1 2.1-1.5 3.1-3.7 3.1-6.5 0-1.7-.3-3.4-.8-5.2-.2-.8-1.6-4.4-4.2-10.6l-10.2-24zm-4.8-10.4l-28.6-66.1-29.5 66.1h58.1z\"/></svg> </div> <% } %> <% if( /(video)/i.test(item.type) ){ %> <div class=\"icon-video\"> <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"-293 204 205 205\"><path d=\"M-145.6 365.9h-145.2c-1.1 0-2-.9-2-2V246.7c0-1.1.9-2 2-2h145.2c1.1 0 2 .9 2 2v117.2c0 1.1-.9 2-2 2zM-105.7 256.2l-32.3 24.5v49.1l32.3 24.6H-88v-98.2\"/></svg> </div> <% } %> <% if( /(topic)/i.test(item.type) ){ %> <span><%- item.label %></span> <% } %> </div> <% } %> "
  , __filename = undefined;
try {
  var __output = "";
  function __append(s) { if (s !== undefined && s !== null) __output += s }
  with (locals || {}) {
    ;  if( item.active === "1" ){ 
    ; __append(" <div class=\"filter-btn")
    ; __append( (/(topic)/i.test(item.type)? ' topic-filter' : ' type-filter' ) )
    ; __append( ( item.selected ? ' active' : '' ) )
    ; __append("\" data-track=\"button : ")
    ; __append( (/(topic)/i.test(item.type) ? 'topic-filter' : 'type-filter') )
    ; __append("\"> ")
    ;  if( /(image)/i.test(item.type) ){ 
    ; __append(" <div class=\"icon-photo\"> <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"-293 204 205 205\"><path d=\"M-293 361.9l46.4-56.5 43.3 33.1 57.6-82.8L-88 361.9\"/><circle cx=\"-244.8\" cy=\"266.4\" r=\"19.2\"/></svg> </div> ")
    ;  } 
    ; __append(" ")
    ;  if( /(text)/i.test(item.type) ){ 
    ; __append(" <div class=\"icon-text\"> <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"-293 204 205 205\"><path d=\"M-174 349.3h-67.8l-8.1 18.7c-2.6 6.2-4 11.4-4 15.4 0 5.4 2.2 9.4 6.5 11.9 2.5 1.5 8.8 2.7 18.8 3.4v5.2h-63.9v-5.2c6.9-1 12.6-3.9 17-8.6 4.4-4.7 9.9-14.3 16.4-29l68.7-153.2h2.7l69.3 157.5c6.6 14.9 12 24.3 16.3 28.2 3.2 2.9 7.7 4.6 13.6 5.1v5.2h-92.9v-5.2h3.8c7.5 0 12.7-1 15.7-3.1 2.1-1.5 3.1-3.7 3.1-6.5 0-1.7-.3-3.4-.8-5.2-.2-.8-1.6-4.4-4.2-10.6l-10.2-24zm-4.8-10.4l-28.6-66.1-29.5 66.1h58.1z\"/></svg> </div> ")
    ;  } 
    ; __append(" ")
    ;  if( /(video)/i.test(item.type) ){ 
    ; __append(" <div class=\"icon-video\"> <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"-293 204 205 205\"><path d=\"M-145.6 365.9h-145.2c-1.1 0-2-.9-2-2V246.7c0-1.1.9-2 2-2h145.2c1.1 0 2 .9 2 2v117.2c0 1.1-.9 2-2 2zM-105.7 256.2l-32.3 24.5v49.1l32.3 24.6H-88v-98.2\"/></svg> </div> ")
    ;  } 
    ; __append(" ")
    ;  if( /(topic)/i.test(item.type) ){ 
    ; __append(" <span>")
    ; __append( item.label )
    ; __append("</span> ")
    ;  } 
    ; __append(" </div> ")
    ;  } 
    ; __append(" ")
  }
  return __output;
} catch (e) {
  rethrow(e, __lines, __filename, __line, escapeFn);
}

}