'use strict';
import _ from 'lodash';
import Backbone from 'backbone';

import template from './../templates/message.ejs';
import BaseView from './../views/base';
import PubSub from './../PubSub';
import CSSView from './css';
var styleId;
import cssTemplate from './../templates/message-css.ejs';

export default BaseView.extend({
    template: template,
    tagName: 'div',
    className: 'view-message',
    /**
     * [events description]
     * @return {[type]} [description]
     */
    events: function() {
        return _.extend(_.result(BaseView.prototype, 'events'), {
            'click .close': 'close'
        });
    },
    /**
     * [initialize description]
     * @param  {[type]} options [description]
     * @return {[type]}         [description]
     */
    initialize: function() {
        _.bindAll(this, 'render');

        styleId = this.className + _.uniqueId();
        this.$el.addClass(styleId);
        this.listenTo(this.model, 'change:copy', this.render);
    },
    /**
     * [render description]
     * @return {[type]} [description]
     */
    render: function() {
        if (this.CSSView) {
            this.CSSView.remove();
        }

        this.CSSView = new CSSView({
            model: this.model.get('copy')['customizations'],
            namespace: '#' + this.model.get('uid') + ' .' + styleId,
            template: cssTemplate
        });

        Backbone.$('head').append(this.CSSView.render().$el);

        this.$el.html(this.template(this.model.get('copy')));

        return this;
    },
    /**
     * [close description]
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    close: function(e) {
        console.log('close');
        if (e) {
            e.preventDefault();
        }
        PubSub.trigger('navigate', 'home');
    }
});
