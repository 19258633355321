'use strict';
const _ = require('lodash');
const $ = require('jquery');
const Backbone = require('backbone');
const modelConnect = require('tscom-connect');

module.exports = modelConnect.extend({
  constructor: function(attributes, options) {
    this.apiUrl =
            (!$.support.cors ? document.location.protocol : 'https:') +
            '//voteapi.votenow.tv/s2/vote';

    this.versionCheck = options.versionCheck;

    modelConnect.call(this, attributes, options);
  },

  sync: function(crud, model, options) {
    console.log('vote model sync', crud, model, options);
    const qsp = this.paramsLiteralToQsp(model.toJSON());
    const b64hash = this.qspToHash(qsp);

    const params = {
      url: this.apiUrl + '?' + qsp,
      type: 'POST',
      crossDomain: true,
      dataType: 'json',
      data: 'Authorization=' + encodeURIComponent(b64hash),
    };

    if (/read/i.test(crud)) {
      _.extend(options, {
        ajaxSync: false,
      });
    }

    _.extend(params, options);

    this.xhr = Backbone.sync(crud, model, params)
        .done(_.bind(this.syncSuccess, this, crud))
        .fail(this.syncError);

    console.log('xhr', this.xhr);

    return this.xhr;
  },

  syncSuccess: function(crud, response) {
    this.trigger('sync', response, this)
  },

});