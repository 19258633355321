'use strict';
import _ from 'lodash';
import $ from 'jquery';
import Backbone from 'backbone';
import PubSub from './../PubSub';
import TLDRC from 'tldr-child';

import ViewError from './../views/error';
import ViewLoading from './../views/loading';
import ViewPage from './../views/page';
import ViewModal from './../views/modal';
import ViewMosaic from './../views/mosaic';
import ViewMediaDetail from './../views/media-detail';
import ViewUploader from './../views/uploader';
import ViewMessage from './../views/message';
import CSSView from './../views/css';

import { windowlessrouter } from 'tscom-util';

import ModelBase from './../models/base';
import ModelUploader from './../models/uploader';
import ModelCms from 'tscom-cms';
import ModelTailor from './../models/tailor';
import ModelConnect from './../models/connect';

import { getQueryParamByName } from './../util/helpers';
import { UPLOADER_VERSION_CHECK } from '../util/constants';

export default function routerConstructor(options) {
    var hashState = options.hashState;
    var Router = !hashState ? windowlessrouter : Backbone.Router;

    return Router.extend({
        controller: null,
        currentView: null,
        currentModal: null,
        routes: {
            '': 'home',
            home: 'home',
            mosaic: 'mosaic',
            'error/:name': 'error',
            'loading/:name': 'loading',
            'media/:id': 'media',
            uploader: 'uploader',
            'uploader-thanks': 'uploaderThanks',
            'browse-happy': 'browseHappy',
            window: 'window',
            geo: 'geo',
            empty: 'empty',
            'no-filter': 'noFilter'
        },
        history: [],
        /**
         *
         */
        initialize: function (options) {
            if (_.isUndefined(options)) {
                return;
            }

            _.bindAll(this, 'handleViewEvent');

            this.controller = options.controller;
            this.instanceID = $(options.el || document.body).attr('id');
            this.uid = options.uid;
            this.initLoad = true; // for TLDR

            this.styleId =
                'telescopeWidget' +
                (new Date().getTime() + Math.round(Math.random() * 10));

            //pym child init
            this.pym = TLDRC.Child({ polling: 500 });
            this.pymChildId = getQueryParamByName('childId') || '';

            this.position = 0;

            this.listenTo(
                PubSub,
                'navigate',
                function (route) {
                    this.navigate(route, { trigger: true });
                },
                this
            );

            this.listenTo(
                PubSub,
                '_back',
                function () {
                    this._back();
                },
                this
            );

            this.listenTo(
                PubSub,
                'AnimationFrame',
                this.calculateLayout.bind(this)
            );
            this.listenTo(
                PubSub,
                'modals:close',
                this.closedCurrentModal.bind(this)
            );

            // opening the tile modal and navigating through the mosaic
            this.listenTo(PubSub, 'media:open', this.media.bind(this));
            this.listenTo(PubSub, 'media:prev', this.mediaPrev.bind(this));
            this.listenTo(PubSub, 'media:next', this.mediaNext.bind(this));

            this.listenTo(PubSub, 'tile:remove', this.removeMessageFromCollection.bind(this));

            this.listenTo(PubSub, 'uploader:open', this.uploader.bind(this));
            this.listenTo(PubSub, 'no-filter', this.noFilter.bind(this));
            this.listenTo(PubSub, 'empty', this.empty.bind(this));
            this.listenTo(PubSub, 'track-event', this.trackEvent.bind(this)); // google analytics
            this.listenTo(PubSub, 'feed-refresh', this.resetModalStyles.bind(this));
            this.listenTo(PubSub, 'pym:scroll:parent', function () {
                this.pym.sendMessage('scroll', 'hi');
            }.bind(this));

            this.listenTo(PubSub, 'uploader:tailor:init', this.tailor.bind(this));

            this.listenTo(this.controller.Models.Cms, 'change:sid', function (model) {
                this.updatePollRate(model, model.get('text').config.cms_poll_rate_in_seconds);
                if (this.controller.Models.CmsForm) {
                    var newFormPollRate = model.get('text').config.cms_form_poll_rate_in_seconds
                    this.updatePollRate(this.controller.Models.CmsForm, newFormPollRate);
                }
            }.bind(this));

            //getting window orientation from parent site
            this.pym.onMessage(
                'pym-orientation',
                _.bind(this.handleOrientationChange, this)
            );

            this.listenTo(
                PubSub,
                'fullscreen-event',
                _.bind(this.sendFullScreenEventToParent, this)
            );
            //this.listenTo(PubSub, "pym-message", _.bind(this.pymMessageDispatcher, this));

            // Receive scroll position from parent
            this.pym.onMessage(
                'pym-scroll-event',
                _.bind(function (data) {
                    var dataObj = JSON.parse(data);

                    if (!_.isUndefined(dataObj)) {
                        this.position = _.isUndefined(dataObj.scrollTop)
                            ? 0
                            : dataObj.scrollTop;
                    }

                    //if inside iframe
                    if (window.self !== window.top) {
                        var position = this.position;
                        var modalTop = position + 'px';
                        $('.modal-window').css('padding-top', modalTop);
                        this.$body.css('height', '100%');
                    }
                }, this)
            );

            //entry point for scrolling event message from pym parent
            this.pym.onMessage(
                'pym-scrolling',
                _.bind(this.controller.setScrollFlag, this.controller)
            );

            //custom event - open modal from external click in parent site
            this.pym.onMessage(
                'customEvent',
                function (eventName) {
                    switch (eventName) {
                        case 'openModal':
                            this.controller.Models.Google.set({
                                eventCategory: 'external click',
                                eventAction: 'call upload form'
                            });

                            this.controller.Models.Google.sendGA();

                            this.uploader();
                            break;

                        default:
                    }
                }.bind(this)
            );

            this.on('route', _.bind(this._saveHistory, this));

            // this is for when user navigates modal tiles using keyboard arrows
            $(document).on('keydown', function (e) {
                PubSub.trigger('keypress:down', e);
            });

            var displayLandscapeMosiac = this.controller.Models.Cms.get('text')
                .global_customizations['16x9_ratio'];

            this.page = new ViewPage({
                model: this.controller.Models.Page,
                uploader: this.controller.Models.Cms.get('text').view_uploader,
                displayLandscape:
                    displayLandscapeMosiac &&
                    displayLandscapeMosiac.trim() == 'true'
            }).render();

            this.$body = $(options.el || document.body);
            this.$modalScope = $(options.el || document.body);

            this.$body.addClass(this.styleId);

            this.$app = this.page.$('div[role="main"]');
            this.$modal = $(
                '<div class="modal"' +
                (_.isEqual(options.container, options.modal)
                    ? ''
                    : ' id="' + options.uid + 'Modal"') +
                ' />'
            );

            var ua = window.navigator.userAgent;
            //internet explorer
            if (
                ua.indexOf('MSIE ') > -1 ||
                (ua.indexOf('Trident') > -1 && ua.indexOf('rv:11') > -1)
            ) {
                this.$app.addClass('ie-browser');
                this.$modal.addClass('ie-browser');
            }

            this.$modal.addClass(this.styleId);

            console.log('Router initialize', this.instanceID);

            this.$body.html('').append(this.page.$el);
            this.$modalScope.append(this.$modal);

            //check if desktop or mobile
            if (
                /Android|Mobile|webOS|iPhone|iPad|iPod|Kindle|BlackBerry|PalmOS|PalmSource|Opera Mini|IEMobile|SonyEricsson|smartphone/i.test(
                    navigator.userAgent
                ) === true
            ) {
                this.$modal.addClass('mobile');
            } else {
                this.$modal.addClass('desktop');
            }

            this.css();

            //required when fan feed is iframed into a site
            this.pym.child.onMessage(
                'pym:load:tiles',
                _.throttle(
                    function () {
                        if (this.currentView) {
                            if (
                                typeof this.currentView.loadTiles === 'function'
                            ) {
                                this.currentView.loadTiles();
                            }
                        }
                    }.bind(this),
                    300
                )
            );

            //window and document event listeners -------------------------------------------
            $(document).on(
                'click',
                'input[type="button"],input[type="submit"],select,button,a,.filter-btn,.share-btn',
                _.bind(this.handleViewEvent, this)
            );

            var animationFrameHandler = _.bind(function (timestamp) {
                PubSub.trigger('AnimationFrame', timestamp);
                window.requestAnimationFrame(animationFrameHandler);
            }, this);
            window.requestAnimationFrame(animationFrameHandler);

            this.$body.disableSelection();

            //getting the orientation when this app is not iframed.
            window.addEventListener(
                'orientationchange',
                _.bind(function () {
                    this.handleOrientationChange(window.orientation);
                }, this)
            );

            $(document).on(
                'webkitfullscreenchange mozfullscreenchange fullscreenchange',
                _.bind(function () {
                    var documentState =
                        document.fullScreen ||
                        document.mozFullScreen ||
                        document.webkitIsFullScreen;
                    var state = documentState
                        ? 'FullscreenOn'
                        : 'FullscreenOff';
                    console.log($(window).scrollTop());

                    this.sendFullScreenEventToParent(state);
                }, this)
            );
            //end of window and document event listeners------------------------------
        },
        /**
         *
         */
        setCurrentView: function (view, tracking) {
            var classNames = _.trim(
                view.className.replace(/(^|\s)([a-z\d-]+)/gi, 'body-$2 ')
            );

            if (
                this.currentModal &&
                this.currentModal.model.view.className.indexOf(
                    'view-media-detail'
                ) === -1
            )
                this.closeCurrentModal();

            this.$app.html(view.render().$el);

            this.$app.alterClass('body-*', classNames);
            this.page.$el.alterClass('body-*', classNames);

            this.$body = $(options.el || document.body);

            if (
                this.currentModal &&
                this.currentModal.model.view.className.indexOf(
                    'view-media-detail'
                ) > -1
            ) {
                this.$body.addClass('no-scroll');
            }

            if (/iPhone|iPad|iPod/i.test(navigator.userAgent) === true) {
                this.$body.removeAttr('style');
            } else {
            }

            // remove old view
            if (this.currentView !== null) {
                this.currentView.remove();
                this.currentView = null;
            }
            // store current view
            this.currentView = view;

            if (typeof this.currentView.renderedToDom === 'function') {
                this.currentView.renderedToDom();
            }

            if (_.isString(tracking)) {
                this.handlePageView(tracking);
            }
        },
        /**
         *
         */
        setCurrentModal: function (view, tracking) {
            var classNames = _.trim(
                view.className.replace(/(^|\s)([a-z\d-]+)/gi, 'modal-has-$2 ')
            );
            var modal = new ViewModal({
                model: { view: view },
                controller: this.controller
            });

            this.position = $(document).scrollTop();

            this.$body = $(options.el || document.body);

            this.page.$el.alterClass('modal-has-*', classNames);
            modal.$el.alterClass('modal-has-*', classNames);

            this.$modal.css('display', 'block');
            this.$modal.html(modal.render().$el);

            //if inside iframe
            if (window.self !== window.top) {
                this.$body.css('height', '100%');
                var height = this.$body.outerHeight();
                $('.modal').css('min-height', height);
                $('.modal-window').css('min-height', height);
                document.body.classList.add('frame-true');
            }

            //Fix for mobile viewport issues
            if ($('.modal').hasClass('mobile')) {
                $('.modal-content').css('max-width', '320px');
            }

            // remove old modal
            if (this.currentModal !== null) {
                if (_.isObject(this.currentModal.model.view)) {
                    this.currentModal.model.view.remove();
                }
                this.currentModal.remove();
                this.currentModal = this.controller.Views.Modal = null;
            }
            // store current modal
            this.currentModal = this.controller.Views.Modal = modal;

            if (typeof this.currentModal.renderedToDom === 'function') {
                this.currentModal.renderedToDom();
            }

            if (
                typeof this.currentModal.model.view.renderedToDom === 'function'
            ) {
                this.currentModal.model.view.renderedToDom();
            }

            if (_.isString(tracking)) {
                this.handlePageView(tracking);
            }

            var messagePayload = {
                status: 'open',
                name: view.className
            };

            this.pym.sendMessage(
                'pym-child-modal-event',
                JSON.stringify(messagePayload)
            );
            PubSub.trigger('timer:stop');

            this.handleOrientationChange();
            this.$body.addClass('no-scroll');

            if (top !== self) {
                // if inside iframe but not using pym, use scrollTo(0,0) and return early
                if (!getQueryParamByName('parentUrl')) {
                    window.scrollTo(0, 0);
                    return;
                }
                var position = -1 * this.position + 'px';
                this.$body.css('top', position);

                if (!this.initLoad) {
                    // TLDR - make sure on parent page, allowStoredPosition is set to true
                    var coord = JSON.stringify({ x: 0, y: 0 });
                    this.pym.sendMessage('storeCurrentPosition');
                    this.pym.sendMessage('scrollToChildPosition', coord);

                    this.pym.sendMessage(
                        'modal-open',
                        JSON.stringify(messagePayload)
                    );
                } else {
                    this.initLoad = false;
                }
            }

        },
        /**
         *
         */
        closedCurrentModal: function () {
            console.log('MODAL HIDDEN!!');
            var messagePayload = {};

            if (this.currentModal !== null) {
                if (_.isObject(this.currentModal.model.view)) {
                    messagePayload.name = this.currentModal.model.view.className;
                    this.currentModal.model.view.remove();
                } else {
                    messagePayload.name = 'unknown';
                }

                this.currentModal.remove();
                this.currentModal = this.controller.Views.Modal = null;
                this.page.$el.alterClass('modal-has-*', '');

                this.$body.removeClass('no-scroll');
                var position = this.position + 'px';
                $('html, body').animate({ scrollTop: position }, 0);
                //$(window).scrollTop(this.position);
                this.$body.removeAttr('top');
                this.$modal.css('display', 'none');

                messagePayload.status = 'closed';
                this.pym.sendMessage(
                    'pym-child-modal-event',
                    JSON.stringify(messagePayload)
                );

                // if( /iPhone|iPad|iPod/i.test(navigator.userAgent) === true && window.self == window.top) {
                //     this.$body.removeAttr('style');
                // } else {

                // }

                // TLDR - make sure on parent page, allowStoredPosition is set to true
                if (top !== self) {
                    this.pym.sendMessage('scrollToStoredPosition');
                }

                PubSub.trigger('timer:reset');
            }
        },
        /**
         *
         */
        closeCurrentModal: function () {
            console.log('CLOSE CURRENT MODAL');
            this.closedCurrentModal();
        },
        /**
         * [css description]
         * @return {[type]} [description]
         */
        css: function (model) {
            model = model ||
                this.controller.Models.Cms.get('text')[
                'global_customizations'
                ] || {
                font_family: 'FacitWeb',
                font_import:
                    'http://assets.votenow.tv/fonts/facit/FacitWeb.css'
            };

            this.CSSView = new CSSView({
                model: model,
                modal: '#' + this.uid + 'Modal',
                namespace: '#' + this.uid + ' ' /*', #' + this.uid + 'Modal'*/,
                styleId: this.styleId
            });

            Backbone.$('head').append(this.CSSView.render().$el);
        },

        tailor: function () {
            console.log('Set Up Tailor', this.controller.userIp);
            var geoheaders = this.controller.Models.Geo.get('geoheaders');

            var tailorOptions = {
                auth_token: this.controller.Models.Cms.get('text')[
                    'view_uploader'
                ].tailor_auth_token,
                topics: parseInt(
                    this.controller.Models.Cms.get('text')['view_uploader']
                        .flex_topic_id,
                    10
                ),
                userIp: this.controller.userIp,
                geoCountry: geoheaders.country,
                geoState: geoheaders.region
            };

            var rulesetCollectionId = this.controller.Models.Cms.get('text')[
                'view_uploader'
            ]['ruleset_collection_id'];

            if (rulesetCollectionId && !!parseInt(rulesetCollectionId)) {
                tailorOptions['ruleset_collection_ids'] = rulesetCollectionId;
                // tailorOptions[ "ruleset_collection_ids" ] = rulesetCollectionId;
            }

            this.controller.Models.Tailor = new ModelTailor({}, tailorOptions);

            this.stopListening(this.controller.Models.Tailor, 'sync');
            this.listenTo(
                this.controller.Models.Tailor,
                'sync',
                _.bind(function () {
                    this.stopListening(this.controller.Models.Tailor);
                    this.uploaderThanks();
                }, this)
            );

            // pass new tailor to uploader (this is after there was a tailor error)
            PubSub.trigger(
                'uploader:tailor:setup',
                this.controller.Models.Tailor
            );
        },

        /**
         *
         */
        home: function () {
            console.log('ROUTE HOME');
            if (!this._isOpen('home')) {
                return;
            }
            var topicFilters = _.find(
                this.controller.Collections.Filter.toJSON(),
                { selected: true, type: 'topic' }
            );
            var typeFilters = _.find(
                this.controller.Collections.Filter.toJSON(),
                function (item) {
                    return (
                        item.selected === true &&
                        /(text|image|video)/i.test(item.type)
                    );
                }
            );

            if (_.isUndefined(topicFilters) || _.isUndefined(typeFilters)) {
                this.navigate('no-filter', { trigger: true });
            } else {
                this.navigate('mosaic', { trigger: true });
            }

            // @TODO mosaic view has loading icon until this.controller.Collections.Message.length>0
            // or if the request(s) to moderation finish and the payload contains no items,
            // send user to this.navigate('empty', {trigger: true});
        },
        /**
         * [mosaic description]
         * @return {[type]} [description]
         */
        mosaic: function () {
            if (!this._isOpen('mosaic')) {
                return;
            }

            if (this.controller.Models.Mosaic) {
                this.controller.Models.Mosaic.destroy();
                this.controller.Models.Mosaic = null;
            }
            var topicFilters = _.filter(
                this.controller.Collections.Filter.toJSON(),
                { type: 'topic' }
            );
            var typeFilters = _.map(
                _.pluck(
                    _.filter(
                        this.controller.Collections.Filter.toJSON(),
                        function (item) {
                            return (
                                item.selected === true &&
                                /(text|image|video)/i.test(item.type)
                            );
                        }
                    ),
                    'type'
                ),
                function (item) {
                    return item.toLowerCase();
                }
            );

            console.log('ROUTE MOSAIC', typeFilters);

            var numTileLimit = parseInt(
                this.controller.Models.Cms.get(
                    'text'
                ).config.maximum_tiles.trim(),
                10
            );
            numTileLimit = _.isNaN(numTileLimit) ? null : numTileLimit;

            var model = (this.controller.Models.Mosaic = new ModelBase(
                {
                    copy: _.clone(
                        this.controller.Models.Cms.get('text').view_mosaic,
                        true
                    ),
                    uid: this.uid,
                    messages: this.controller.Collections.Message,
                    numTileLimit: numTileLimit,
                    type_filters: typeFilters,
                    topic_filters: topicFilters
                },
                { textKey: 'view_mosaic', cms: this.controller.Models.Cms }
            ));

            var view = new ViewMosaic({
                model: model
            });

            this.setCurrentView(view, 'mosaic');
        },
        /**
         * [media description]
         * @param  {[type]} id [description]
         * @return {[type]}    [description]
         */
        media: function (id) {
            console.log('ROUTE MEDIA', id);
            if (!this._isOpen('media/' + id)) {
                return;
            }

            if (this.controller.Models.Media) {
                this.controller.Models.Media.destroy();
                this.controller.Models.Media = null;
            }

            var data = this.controller.Collections.Message.get(id);
            var currentIndex = this.controller.Collections.Message.indexOf(
                data
            );
            var isFirst = currentIndex === 0;
            var isLast =
                currentIndex === this.controller.Collections.Message.length - 1;

            var mediaType;

            if (/(contains_vine)/i.test(data.attributes.contains)) {
                mediaType = 'vine';
            } else if (/(contains_video)/i.test(data.attributes.contains)) {
                mediaType = 'video';
            } else if (/(contains_image)/i.test(data.attributes.contains)) {
                mediaType = 'image';
            } else {
                mediaType = 'text';
            }

            var model = (this.controller.Models.Media = new ModelBase(
                {
                    copy: _.clone(
                        this.controller.Models.Cms.get('text').view_media,
                        true
                    ),
                    uid: this.uid,
                    data: data.toJSON(),
                    isFirst: isFirst,
                    isLast: isLast,
                    type: mediaType
                },
                { textKey: 'view_media', cms: this.controller.Models.Cms }
            ));

            var view = new ViewMediaDetail({
                model: model,
                dataModel: data,
                className: 'view-media-detail view-media-detail-' + mediaType,
                custom: this.controller.Models.Cms.get('text')['view_mosaic']
                    .customizations.display_instagram_actions,
                flexShare: this.controller.Models.Cms.get('text')[
                    'view_mosaic'
                ]['sharing_flex']
            });

            this.setCurrentModal(view, 'media-detail-' + id);
        },
        /**
         * Open media tile modal for previous tile
         */
        mediaPrev: function (model) {
            var currentIndex = this.controller.Collections.Message.indexOf(
                model
            );
            var prevIndex = currentIndex - 1;
            var prevModel = this.controller.Collections.Message.at(prevIndex);

            if (prevModel && prevIndex >= 0) {
                PubSub.trigger('media:open', prevModel.get('id'));
            }
        },
        /**
         * Open media tile modal for next tile
         */
        mediaNext: function (model) {
            var currentIndex = this.controller.Collections.Message.indexOf(
                model
            );
            var nextIndex = currentIndex + 1;
            var nextModel = this.controller.Collections.Message.at(nextIndex);

            if (
                nextModel &&
                nextIndex <= this.controller.Collections.Message.length - 1
            ) {
                PubSub.trigger('media:open', nextModel.get('id'));
            }
        },
        /**
         * [uploader description]
         * @return {[type]} [description]
         */
        uploader: function () {
            console.log('ROUTE UPLOADER', this.instanceID);
            if (!this._isOpen('uploader')) {
                return;
            }

            var uploaderWid = this.controller.Models.Cms.get('settings').uploader_wid;
            var cmsFormPollRate = this.controller.Models.Cms.get('text').config.cms_form_poll_rate_in_seconds;

            if (_.isUndefined(this.controller.Models.CmsForm)) {
                console.log('define CmsForm Model');

                var pollRate = 30;
                if (cmsFormPollRate) {
                    pollRate = parseInt(cmsFormPollRate, 10) < 3 ? 3 : parseInt(cmsFormPollRate, 10);
                }

                var CmsForm = (this.controller.Models.CmsForm = new ModelCms({
                    wid: uploaderWid ? uploaderWid : 'a16060d146a54416',
                    updateFrequency: pollRate
                }, { ignoreQSP: true }));

                CmsForm.fetch().success(
                    _.bind(function () {
                        // this.navigate('uploader', {trigger: true})
                        this.uploader();
                    }, this)
                );
                return;
            }

            console.log('defined CmsForm Model');

            if (this.controller.Models.Uploader) {
                this.controller.Models.Uploader.destroy();
                this.controller.Models.Uploader = null;
            }

            if (this.controller.Models.UploaderView) {
                this.controller.Models.UploaderView.destroy();
                this.controller.Models.UploaderView = null;
            }

            var connect = new ModelConnect({
                apiKey: this.controller.Models.CmsForm.get('settings').apiKey,
            }, {
                controller: this.controller,
                version_id: this.controller.Models.CmsForm.get('settings').version_id,
                versionCheck: UPLOADER_VERSION_CHECK
            });

            this.tailor();

            var modelUploader = (this.controller.Models.Uploader = new ModelUploader(
                {},
                { fieldData: this.controller.Models.CmsForm.get('data') }
            ));
            var model = (this.controller.Models.UploaderView = new ModelBase(
                {
                    copy: _.clone(
                        this.controller.Models.Cms.get('text').view_uploader,
                        true
                    ),
                    uid: this.uid,
                    model: modelUploader
                },
                { textKey: 'view_uploader', cms: this.controller.Models.Cms }
            ));

            var view = new ViewUploader({
                flex: this.controller.Models.Flex,
                tailor: this.controller.Models.Tailor,
                connect: connect,
                model: model
            });

            this.setCurrentModal(view, 'uploader');
        },
        /**
         *
         */
        error: function (name) {
            console.log('ROUTE ERROR', name, this.instanceID);
            var data = this.controller.Models.Cms.get('text')['views_error'][
                name
            ];
            data = _.isObject(data)
                ? data
                : this.controller.Models.Cms.get('text')['views_error'][
                'generic'
                ];
            var view = new ViewError({
                model: data,
                className: 'view-error-' + name,
                controller: this.controller
            });
            this.setCurrentModal(view, 'view-error-' + name);
        },
        /**
         *
         */
        loading: function (name) {
            console.log('ROUTE LOADING', this.instanceID);
            var data = this.controller.Models.Cms.get('text')['views_loading'][
                name
            ];
            data = _.isObject(data)
                ? data
                : this.controller.Models.Cms.get('text')['views_loading'][
                'generic'
                ];
            var view = new ViewLoading({
                model: data,
                className: 'view-loading-' + name,
                controller: this.controller
            });
            this.setCurrentModal(view, 'view-loading-' + name);
        },
        /**
         *
         */
        uploaderThanks: function () {
            console.log('ROUTE THANKS', this.instanceID);
            if (!this._isOpen('uploader-thanks')) {
                return;
            }
            if (this.controller.Models.UploaderThanks) {
                this.controller.Models.UploaderThanks.destroy();
                this.controller.Models.UploaderThanks = null;
            }
            var model = (this.controller.Models.UploaderThanks = new ModelBase(
                {
                    copy: _.clone(
                        this.controller.Models.Cms.get('text')
                            .view_uploader_thanks,
                        true
                    ),
                    uid: this.uid
                },
                {
                    textKey: 'view_uploader_thanks',
                    cms: this.controller.Models.Cms
                }
            ));
            var view = new ViewMessage({
                model: model,
                className: ViewMessage.prototype.className + '-uploader-thanks'
            });
            this.setCurrentModal(view, 'uploader-thanks');
        },
        /**
         *
         */
        empty: function () {
            console.log('ROUTE MOSAIC EMPTY', this.instanceID);
            if (this.controller.Models.MosiacEmpty) {
                this.controller.Models.MosiacEmpty.destroy();
                this.controller.Models.MosiacEmpty = null;
            }
            var model = (this.controller.Models.MosiacEmpty = new ModelBase(
                {
                    copy: _.clone(
                        this.controller.Models.Cms.get('text')
                            .view_mosaic_empty,
                        true
                    ),
                    uid: this.uid
                },
                {
                    textKey: 'view_mosaic_empty',
                    cms: this.controller.Models.Cms
                }
            ));
            var view = new ViewMessage({
                model: model,
                className: ViewMessage.prototype.className + '-mosaic-empty'
            });
            this.setCurrentView(view, 'mosaic-empty');
        },
        /**
         *
         */
        noFilter: function () {
            console.log('ROUTE NO FILTER', this.instanceID);
            if (this.controller.Models.NoFilter) {
                this.controller.Models.NoFilter.destroy();
                this.controller.Models.NoFilter = null;
            }
            var model = (this.controller.Models.NoFilter = new ModelBase(
                {
                    copy: _.clone(
                        this.controller.Models.Cms.get('text').view_no_filter,
                        true
                    ),
                    uid: this.uid
                },
                { textKey: 'view_no_filter', cms: this.controller.Models.Cms }
            ));
            var view = new ViewMessage({
                model: model,
                className: ViewMessage.prototype.className + '-no-filter'
            });
            this.setCurrentView(view, 'no-filter');
        },
        /**
         *
         */
        window: function () {
            console.log('ROUTE WINDOW CLOSED', this.instanceID);
            if (this.controller.Models.Window) {
                this.controller.Models.Window.destroy();
                this.controller.Models.Window = null;
            }
            var model = (this.controller.Models.Window = new ModelBase(
                {
                    copy: _.clone(
                        this.controller.Models.Cms.get('text').view_window,
                        true
                    ),
                    uid: this.uid
                },
                { textKey: 'view_window', cms: this.controller.Models.Cms }
            ));
            var view = new ViewMessage({
                model: model,
                className: ViewMessage.prototype.className + '-window-closed'
            });
            this.setCurrentView(view, 'window-closed');
        },
        /**
         *
         */
        browseHappy: function () {
            console.log('ROUTE UNSUPPORTED BROWSER', this.instanceID);
            if (this.controller.Models.BrowseHappy) {
                this.controller.Models.BrowseHappy.destroy();
                this.controller.Models.BrowseHappy = null;
            }
            var model = (this.controller.Models.BrowseHappy = new ModelBase(
                {
                    copy: _.clone(
                        this.controller.Models.Cms.get('text').view_unsupported,
                        true
                    ),
                    uid: this.uid
                },
                { textKey: 'view_unsupported', cms: this.controller.Models.Cms }
            ));
            var view = new ViewMessage({
                model: model,
                className:
                    ViewMessage.prototype.className + '-unsupported-browser'
            });
            this.setCurrentModal(view, 'unsupported-browser');
        },
        /**
         *
         */
        geo: function () {
            console.log('ROUTE GEO', this.instanceID);
            if (this.controller.Models.GeoView) {
                this.controller.Models.GeoView.destroy();
                this.controller.Models.GeoView = null;
            }
            var model = (this.controller.Models.GeoView = new ModelBase(
                {
                    copy: _.clone(
                        this.controller.Models.Cms.get('text').view_geo,
                        true
                    ),
                    uid: this.uid
                },
                { textKey: 'view_geo', cms: this.controller.Models.Cms }
            ));
            var view = new ViewMessage({
                model: model,
                className: ViewMessage.prototype.className + '-geo'
            });
            this.setCurrentView(view, 'geo');
        },
        /**
         * Remove a message from Message Collection when image is broken
         */
        removeMessageFromCollection: function (id) {
            console.log('Removing message from collection: Broken Image', id);
            var message = this.controller.Collections.Message.get(id);
            const removed = this.controller.Collections.Message.remove(message);
            this.controller.Collections.InvalidMessage.add(removed);
        },
        /**
         *
         */
        _isOpen: function (requestedFragment) {
            console.log('_isOpen', requestedFragment);
            // 1. If we are in one of the allowed regions, AND the window
            //    is open, immedately return true.  In all other cases,
            //    return false.
            // 2. If the region data has not returned yet, CHANGE the url
            //    to show loading, then once the region is available, CHANGE
            //    the url back to what it was at the time of the call
            // 3. If the region data has returned, but is not valid, CHANGE
            //    the url show out-of-geo
            // 4. If the region data has returned and is good, but the window
            //    is not open, CHANGE the url to the closed window page
            requestedFragment = requestedFragment || 'home';

            // is the user out of the geographically acceptable region
            if (_.isNull(this.controller.Models.Geo.get('inRegion'))) {
                this.controller.Models.Geo.once(
                    'change:inRegion',
                    _.bind(function () {
                        this.navigate(requestedFragment, { trigger: true });
                    }, this)
                );
                this.navigate('loading/generic', { trigger: true });
                return false;
            } else if (!this.controller.Models.Geo.get('inRegion')) {
                this.navigate('geo', { trigger: true });
                return false;
            }

            // is the window closed?
            if (
                parseInt(
                    this.controller.Models.Cms.get('settings').window_status,
                    10
                ) === 0
            ) {
                this.navigate('window', { trigger: true });
                return false;
            }

            return true;
        },
        /**
         *
         */
        _saveHistory: function (route, params) {
            console.log('listener route ' + this.instanceID, route, params);
            this.history.unshift({
                route: route,
                params: params
            });
        },
        /**
         *
         */
        _back: function () {
            if (!hashState) {
                var previous = this.history[0] || {};
                var route = previous.route || 'landing';
                var params = previous.params || [];
                console.log('_back', route, params);
                this[route].apply(this, params);
            } else {
                window.history.back();
            }
        },
        /**
         *
         * @param route
         * @private
         */
        handlePageView: function (route) {
            console.log('handlePageView', route);

            this.controller.Models.Google.set({
                eventCategory: 'page view',
                eventAction: route
            });

            if (/(media-detail)/i.test(route)) return;

            this.controller.Models.Google.sendGA();
        },
        /**
         *
         * @param e
         * @param eventName
         */
        handleViewEvent: function (e, eventName) {
            console.log('handleViewEvent', e, eventName);

            var category = '';
            var action = '';
            var tag = _.isString(e) ? '' : e.currentTarget.nodeName;
            var el = _.isString(e) ? '' : $(e.currentTarget);
            var data =
                _.isString(eventName) && eventName.length > 0
                    ? eventName
                    : el.is('[data-track]')
                        ? el.attr('data-track')
                        : undefined;

            switch (tag.toLowerCase()) {
                case 'a':
                    category = 'link';
                    action = el.text() + ' - ' + el.prop('href');
                    break;
                case 'button':
                    category = 'button';
                    action = el.text();
                    break;
                case 'input':
                    category = 'button';
                    action = el.val() + ' - ' + el.prop('type');
                    break;
            }

            category = _.isString(eventName) ? 'custom' : category;
            action = !_.isUndefined(data) ? data : action;

            // for custom button tracking like topic filters, do a switch on the data to determine
            // category type
            if (category == '') {
                switch (data) {
                    case 'type-filter':
                        category = 'button';
                        break;
                    case 'topic-filter':
                        category = 'button';
                        break;
                    default:
                        category = 'button';
                        break;
                }
            }
            console.log(
                'handleViewEvent',
                'eventCategory:',
                category,
                'eventAction:',
                action
            );
            this.controller.Models.Google.set({
                eventCategory: category,
                eventAction: action
            });
            this.controller.Models.Google.sendGA();
        },
        /**
         * [calculateLayout description]
         * @param  {[type]} timestamp [description]
         * @return {[type]}           [description]
         */
        calculateLayout: function () {
            // console.log('calculateLayout')
            // this animation interval should only run when the app is being iframed
            // if(top===self){
            //     return;
            // }
            // var newHeight = ((this.$modal.children().length > 0 && $('.modal-window', this.$modal).outerHeight() > this.$body.outerHeight()) ? $('.modal-window', this.$modal).outerHeight() : this.$body.outerHeight() );
            // // no change detected... back to go
            // if(newHeight === this.currentHeight){
            //     return;
            // }
            // console.log('calculateLayout', this.currentHeight );
            // // update the frame height
            // this.currentHeight = newHeight;
            // this.pym.sendHeight();
        },
        /**
         *
         */
        userAgent: {
            android: function () {
                return navigator.userAgent.match(/Android/i);
            },
            iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad/i);
            },
            isDesktop: function () {
                if (
                    /Android|Mobile|webOS|iPhone|iPad|iPod|Kindle|BlackBerry|PalmOS|PalmSource|Opera Mini|IEMobile|SonyEricsson|smartphone/i.test(
                        navigator.userAgent
                    ) === true
                ) {
                    return;
                }
                return 'Pc';
            },
            any: function () {
                return (
                    this.controller.userAgent.android() ||
                    this.controller.userAgent.iOS() ||
                    this.controller.userAgent.isDesktop()
                );
            },
            imageCategory: function () {
                var category =
                    this.controller.userAgent.isDesktop() === 'Pc'
                        ? 'desktop'
                        : window.screen.width < 481
                            ? 'mobile'
                            : 'desktop';
                category += window.devicePixelRatio > 1 ? '_retina' : '';
                return category;
            }
        },
        /**
         *
         */
        scrollToTop: function () {
            // if(top!==self){
            //   this.pym.scrollParentTo('votingframe');
            // }
            // $("html, body").animate({ scrollTop: 0 }, 'fast');
        },
        /**
         *
         */
        trackEvent: function (event) {
            // var controller = this.controller;
            // this.Models.Google.log(eventName);
            var windowStatus = null;
            var googleEvent = {};

            googleEvent[event.name] = {
                name: event.label,
                category: event.category,
                action: event.action
            };

            this.controller.Models.Google.set(googleEvent);
            //this.controller.Models.Google.device = getDevice();
            windowStatus = this.controller.Models.Cms.get('settings')
                .window_status;

            this.controller.Models.Google.log(event.name, windowStatus);
        },
        /**
         *
         */
        sendFullScreenEventToParent: function (state) {
            this.pym.sendMessage('fullscreen-event', state);
        },
        /**
         * sends outgoing message to pym child/parent
         * @param  {[type]} messageObj: comes in a form of {type: xxx, message: yyy}
         * @return {[type]}
         * - messageObj.type: the pym message
         * - messageObj.message: the payload/data
         */
        pymMessageDispatcher: function (messageObj) {
            console.log('pym-message: ', messageObj);
            this.pym.sendMessage(
                messageObj.type,
                JSON.stringify(messageObj.message)
            );
        },
        /**
         *
         */
        resetModalStyles: function () {
            $('.modal-window').css('padding-top', '0px');
        },
        /**
         * handle orientation change of the device. if landscape, add padding to modals
         */
        handleOrientationChange: function (deg) {
            var modal = this.$modal[0];

            var orientation = deg ? deg : window.orientation;

            if (!modal) {
                return;
            }

            if (orientation === 90 || orientation === -90) {
                modal.classList.add('landscape');
                modal.classList.remove('portrait');
            } else {
                modal.classList.remove('landscape');
                modal.classList.add('portrait');
            }
        },

        updatePollRate: function (model, cmsPollRate) {
            var pollRate = 30; // default
            if (cmsPollRate) {
                pollRate = parseInt(cmsPollRate, 10) < 3 ? 3 : parseInt(cmsPollRate, 10);
            }
            model.set({ updateFrequency: pollRate });
        },

        remove: function () {
            if (this.currentView !== null) {
                this.currentView.remove();
                this.currentView = null;
            }

            if (this.currentModal !== null) {
                if (_.isObject(this.currentModal.model.view)) {
                    this.currentModal.model.view.remove();
                }
                this.currentModal.remove();
                this.currentModal = this.controller.Views.Modal = null;
            }

            $(document).off(
                'click',
                'input[type="button"],input[type="submit"],select,button,a,.filter-btn,.share-btn',
                this.handleViewEvent
            );

            this.off();
            this.stopListening();
        }
    });
};
